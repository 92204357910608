import {IconSettingsService, SVGIcon} from '@progress/kendo-angular-icons';
import {Injectable} from "@angular/core";
import * as allSVGIcons from "@progress/kendo-svg-icons";
@Injectable()
export class KIconService extends IconSettingsService  {
  private svgDictionary: { [key: string]: SVGIcon } = {
    "sort-asc-small": allSVGIcons.filterIcon,
    "caret-alt-up": allSVGIcons.filterIcon,
    "k-i-more-vertical":allSVGIcons.filterIcon
  };

  public override getSvgIcon(svgIconName: string): SVGIcon {
    // console.log(svgIconName)
    // console.log(this.svgDictionary[svgIconName])
    // console.log(super.getSvgIcon(svgIconName))
    return this.svgDictionary[svgIconName] || super.getSvgIcon(svgIconName);
  }

  private iconDictionary: { [key: string]: string } = {
    'filter': 'ikbs-filter',
    'more-vertical':'ikbs-filter'
    // 'caret-alt-to-left': 'ikbs-back_step',
    // 'caret-alt-left': 'ikbs-left_stop',
    // 'caret-alt-right': 'ikbs-right_stop',
    // 'caret-alt-to-right': 'ikbs-next_step',
    // "more-vertical":'ikbs-filter'
  };

  public override getCustomFontIconClass(iconName: string): string {
    // console.log(iconName)
    return this.iconDictionary[iconName] || super.getCustomFontIconClass(iconName);
  }
}
