// @ts-nocheck
import { Formio } from "@formio/angular";
const Base = (Formio as any).Components.components.component;
const editForm = (Formio as any).Components.components.table.editForm;
const Components = (Formio as any).Components;
const TextAreaeditForm = (Formio as any).Components.components.textarea.editForm;
const Templates = (Formio as any).Templates;
const Utils = (Formio as any).Utils;
const TextFieldComponent = (Formio as any).Components.components.htmlelement;
import * as _ from "lodash";
import tinymce from "tinymce/tinymce.min.js";

function uuidv4(): string {
  return "ikbsxxxxxxxx4xxxyxxxxxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

Templates.current = {
  smytemplate: {
    form: (ctx) => `
        <label  class="${componentID}1" for="e3sm85-file" class="col-form-label" ref="label">
            ${ctx.component.label}
        </label>
        <textarea  class="${componentID}" style="width:100%;" aria-label="editor"></textarea>`,
  },
};

export default class IKBSTinyMce extends Base {
  public componentID = uuidv4();
  static editForm = editForm;

  static schema() {
    return Base.schema({
      type: "IKBSTinyMce",
      label: "Editor",
    });
  }

  static builderInfo = {
    title: "TinyMCE Editor",
    group: "basic",
    icon: "fa fas tool",
    weight: 70,
    documentation: "http://help.form.io/userguide/#table",
    schema: IKBSTinyMce.schema(),
  };

  constructor(component, options, data) {
    super(component, options, data);
  }

  init() {
    tinymce.init({
      selector: `#${this.componentID}`,
      plugins: "lists link image help wordcount code autoresize",
      toolbar:
        "  bold italic underline strikethrough|  bullist numlist outdent indent |  fontsize code  forecolor| alignleft aligncenter alignright alignjustify |  ",
      browser_spellcheck: true,
    });
    super.init();
  }

  render(children) {
    return super.render(`
        <label  class="${this.componentID}1" for="e3sm85-file" class="col-form-label" ref="label">
           ${this.component.label}
        </label>
        <textarea id="${this.componentID}" class="${this.componentID}" style="width:100%;" aria-label="editor"></textarea>`);
  }

  attach(element) {
    tinymce.init({
      min_height: 200,
      selector: `#${this.componentID}`,
      plugins: "lists link image help wordcount code autoresize",
      toolbar:
        "  bold italic underline strikethrough|  bullist numlist outdent indent |  fontsize code  forecolor| alignleft aligncenter alignright alignjustify |  ",
      menubar: false,
      browser_spellcheck: true,
      setup: (editor) => {
        editor.on("change", (e) => {
          console.log(e.level.content);
          this.setValue(e.level.content);
        });
      },
    });
    return super.attach(element);
  }

  //
  setValue(value, flags = {}) {
    if (!value) value = "";
    tinymce.get(`${this.componentID}`).setContent(value);
    return super.setValue(value, flags);
  }
}

IKBSTinyMce.editForm = (...args) => {
  const editForm = TextAreaeditForm(...args);
  const editor = Utils.getComponent(editForm.components, "editor");
  editor.hidden = true;
  return editForm;
};

// Register the component to the Formio.Components registry.
Components.addComponent("IKBSTinyMce", IKBSTinyMce);
