<div class="buffer">
  <kendo-grid
    #grid
    [data]="gridData"
    [loading]="loadingGridFlag"
    [resizable]="true"
    >
    <ng-template kendoGridToolbarTemplate>
      <div class="title1" style="flex: 0.1;">Buffer</div>
      <span class="ikbs-remove kyc_icons"  (click)='trashBuffer()'></span>

    </ng-template>
    @for (column of columns ; track column) {
      <kendo-grid-column
        [field]="column.field"
        [title]="column.title"
        [width]="column.width"
        >
        @if (column.field === 'index'; as rowIndex) {
          <ng-template kendoGridCellTemplate  let-rowIndex="rowIndex" >
            {{rowIndex+1}}
          </ng-template>
        }
        <ng-template kendoGridCellTemplate let-dataItems="dataItem" let-rowIndex="rowIndex">
          @if (column.field !='action') {
            <p> {{dataItems[column.field]}}</p>
          }
          @if (column.field =='action') {
            <div >
              <i  style="cursor: pointer;" class="ikbs-descending" (click)='pasteBuffer(rowIndex,dataItems)'></i>
              <i  style="color:red;margin-left: 10px ;cursor: pointer;" class="ikbs-remove" (click)='deleteBuffer(rowIndex,dataItems)'></i>
            </div>
          }
        </ng-template>
      </kendo-grid-column>
    }
  </kendo-grid>
</div>
