/**-----------------------------------------------------------------------------------------
* Copyright © 2024 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
var setData = require('@progress/kendo-angular-intl').setData;
setData({
  name: "en-GB",
  identity: {
    language: "en",
    territory: "GB"
  },
  territory: "GB",
  numbers: {
    symbols: {
      decimal: ".",
      group: ",",
      list: ";",
      percentSign: "%",
      plusSign: "+",
      minusSign: "-",
      approximatelySign: "~",
      exponential: "E",
      superscriptingExponent: "×",
      perMille: "‰",
      infinity: "∞",
      nan: "NaN",
      timeSeparator: ":"
    },
    decimal: {
      patterns: ["n"],
      groupSize: [3]
    },
    scientific: {
      patterns: ["nEn"],
      groupSize: []
    },
    percent: {
      patterns: ["n%"],
      groupSize: [3]
    },
    currency: {
      patterns: ["$n"],
      groupSize: [3],
      "unitPattern-count-one": "n $",
      "unitPattern-count-other": "n $"
    },
    accounting: {
      patterns: ["$n", "($n)"],
      groupSize: [3]
    },
    currencies: {
      ADP: {
        displayName: "Andorran Peseta",
        "displayName-count-one": "Andorran peseta",
        "displayName-count-other": "Andorran pesetas",
        symbol: "ADP"
      },
      AED: {
        displayName: "United Arab Emirates Dirham",
        "displayName-count-one": "UAE dirham",
        "displayName-count-other": "UAE dirhams",
        symbol: "AED"
      },
      AFA: {
        displayName: "Afghan Afghani (1927–2002)",
        "displayName-count-one": "Afghan afghani (1927–2002)",
        "displayName-count-other": "Afghan afghanis (1927–2002)",
        symbol: "AFA"
      },
      AFN: {
        displayName: "Afghan Afghani",
        "displayName-count-one": "Afghan afghani",
        "displayName-count-other": "Afghan afghanis",
        symbol: "AFN",
        "symbol-alt-narrow": "؋"
      },
      ALK: {
        displayName: "Albanian Lek (1946–1965)",
        "displayName-count-one": "Albanian lek (1946–1965)",
        "displayName-count-other": "Albanian lekë (1946–1965)",
        symbol: "ALK"
      },
      ALL: {
        displayName: "Albanian Lek",
        "displayName-count-one": "Albanian lek",
        "displayName-count-other": "Albanian lekë",
        symbol: "ALL"
      },
      AMD: {
        displayName: "Armenian Dram",
        "displayName-count-one": "Armenian dram",
        "displayName-count-other": "Armenian drams",
        symbol: "AMD",
        "symbol-alt-narrow": "֏"
      },
      ANG: {
        displayName: "Netherlands Antillean Guilder",
        "displayName-count-one": "Netherlands Antillean guilder",
        "displayName-count-other": "Netherlands Antillean guilders",
        symbol: "ANG"
      },
      AOA: {
        displayName: "Angolan Kwanza",
        "displayName-count-one": "Angolan kwanza",
        "displayName-count-other": "Angolan kwanzas",
        symbol: "AOA",
        "symbol-alt-narrow": "Kz"
      },
      AOK: {
        displayName: "Angolan Kwanza (1977–1991)",
        "displayName-count-one": "Angolan kwanza (1977–1991)",
        "displayName-count-other": "Angolan kwanzas (1977–1991)",
        symbol: "AOK"
      },
      AON: {
        displayName: "Angolan New Kwanza (1990–2000)",
        "displayName-count-one": "Angolan new kwanza (1990–2000)",
        "displayName-count-other": "Angolan new kwanzas (1990–2000)",
        symbol: "AON"
      },
      AOR: {
        displayName: "Angolan Readjusted Kwanza (1995–1999)",
        "displayName-count-one": "Angolan readjusted kwanza (1995–1999)",
        "displayName-count-other": "Angolan readjusted kwanzas (1995–1999)",
        symbol: "AOR"
      },
      ARA: {
        displayName: "Argentine Austral",
        "displayName-count-one": "Argentine austral",
        "displayName-count-other": "Argentine australs",
        symbol: "ARA"
      },
      ARL: {
        displayName: "Argentine Peso Ley (1970–1983)",
        "displayName-count-one": "Argentine peso ley (1970–1983)",
        "displayName-count-other": "Argentine pesos ley (1970–1983)",
        symbol: "ARL"
      },
      ARM: {
        displayName: "Argentine Peso (1881–1970)",
        "displayName-count-one": "Argentine peso (1881–1970)",
        "displayName-count-other": "Argentine pesos (1881–1970)",
        symbol: "ARM"
      },
      ARP: {
        displayName: "Argentine Peso (1983–1985)",
        "displayName-count-one": "Argentine peso (1983–1985)",
        "displayName-count-other": "Argentine pesos (1983–1985)",
        symbol: "ARP"
      },
      ARS: {
        displayName: "Argentine Peso",
        "displayName-count-one": "Argentine peso",
        "displayName-count-other": "Argentine pesos",
        symbol: "ARS",
        "symbol-alt-narrow": "$"
      },
      ATS: {
        displayName: "Austrian Schilling",
        "displayName-count-one": "Austrian schilling",
        "displayName-count-other": "Austrian schillings",
        symbol: "ATS"
      },
      AUD: {
        displayName: "Australian Dollar",
        "displayName-count-one": "Australian dollar",
        "displayName-count-other": "Australian dollars",
        symbol: "A$",
        "symbol-alt-narrow": "$"
      },
      AWG: {
        displayName: "Aruban Florin",
        "displayName-count-one": "Aruban florin",
        "displayName-count-other": "Aruban florin",
        symbol: "AWG"
      },
      AZM: {
        displayName: "Azerbaijani Manat (1993–2006)",
        "displayName-count-one": "Azerbaijani manat (1993–2006)",
        "displayName-count-other": "Azerbaijani manats (1993–2006)",
        symbol: "AZM"
      },
      AZN: {
        displayName: "Azerbaijani Manat",
        "displayName-count-one": "Azerbaijani manat",
        "displayName-count-other": "Azerbaijani manats",
        symbol: "AZN",
        "symbol-alt-narrow": "₼"
      },
      BAD: {
        displayName: "Bosnia-Herzegovina Dinar (1992–1994)",
        "displayName-count-one": "Bosnia-Herzegovina dinar (1992–1994)",
        "displayName-count-other": "Bosnia-Herzegovina dinars (1992–1994)",
        symbol: "BAD"
      },
      BAM: {
        displayName: "Bosnia-Herzegovina Convertible Mark",
        "displayName-count-one": "Bosnia-Herzegovina convertible mark",
        "displayName-count-other": "Bosnia-Herzegovina convertible marks",
        symbol: "BAM",
        "symbol-alt-narrow": "KM"
      },
      BAN: {
        displayName: "Bosnia-Herzegovina New Dinar (1994–1997)",
        "displayName-count-one": "Bosnia-Herzegovina new dinar (1994–1997)",
        "displayName-count-other": "Bosnia-Herzegovina new dinars (1994–1997)",
        symbol: "BAN"
      },
      BBD: {
        displayName: "Barbadian Dollar",
        "displayName-count-one": "Barbadian dollar",
        "displayName-count-other": "Barbadian dollars",
        symbol: "BBD",
        "symbol-alt-narrow": "$"
      },
      BDT: {
        displayName: "Bangladeshi Taka",
        "displayName-count-one": "Bangladeshi taka",
        "displayName-count-other": "Bangladeshi takas",
        symbol: "BDT",
        "symbol-alt-narrow": "৳"
      },
      BEC: {
        displayName: "Belgian Franc (convertible)",
        "displayName-count-one": "Belgian franc (convertible)",
        "displayName-count-other": "Belgian francs (convertible)",
        symbol: "BEC"
      },
      BEF: {
        displayName: "Belgian Franc",
        "displayName-count-one": "Belgian franc",
        "displayName-count-other": "Belgian francs",
        symbol: "BEF"
      },
      BEL: {
        displayName: "Belgian Franc (financial)",
        "displayName-count-one": "Belgian franc (financial)",
        "displayName-count-other": "Belgian francs (financial)",
        symbol: "BEL"
      },
      BGL: {
        displayName: "Bulgarian Hard Lev",
        "displayName-count-one": "Bulgarian hard lev",
        "displayName-count-other": "Bulgarian hard leva",
        symbol: "BGL"
      },
      BGM: {
        displayName: "Bulgarian Socialist Lev",
        "displayName-count-one": "Bulgarian socialist lev",
        "displayName-count-other": "Bulgarian socialist leva",
        symbol: "BGM"
      },
      BGN: {
        displayName: "Bulgarian Lev",
        "displayName-count-one": "Bulgarian lev",
        "displayName-count-other": "Bulgarian leva",
        symbol: "BGN"
      },
      BGO: {
        displayName: "Bulgarian Lev (1879–1952)",
        "displayName-count-one": "Bulgarian lev (1879–1952)",
        "displayName-count-other": "Bulgarian leva (1879–1952)",
        symbol: "BGO"
      },
      BHD: {
        displayName: "Bahraini Dinar",
        "displayName-count-one": "Bahraini dinar",
        "displayName-count-other": "Bahraini dinars",
        symbol: "BHD"
      },
      BIF: {
        displayName: "Burundian Franc",
        "displayName-count-one": "Burundian franc",
        "displayName-count-other": "Burundian francs",
        symbol: "BIF"
      },
      BMD: {
        displayName: "Bermudian Dollar",
        "displayName-count-one": "Bermudian dollar",
        "displayName-count-other": "Bermudian dollars",
        symbol: "BMD",
        "symbol-alt-narrow": "$"
      },
      BND: {
        displayName: "Brunei Dollar",
        "displayName-count-one": "Brunei dollar",
        "displayName-count-other": "Brunei dollars",
        symbol: "BND",
        "symbol-alt-narrow": "$"
      },
      BOB: {
        displayName: "Bolivian Boliviano",
        "displayName-count-one": "Bolivian boliviano",
        "displayName-count-other": "Bolivian bolivianos",
        symbol: "BOB",
        "symbol-alt-narrow": "Bs"
      },
      BOL: {
        displayName: "Bolivian Boliviano (1863–1963)",
        "displayName-count-one": "Bolivian boliviano (1863–1963)",
        "displayName-count-other": "Bolivian bolivianos (1863–1963)",
        symbol: "BOL"
      },
      BOP: {
        displayName: "Bolivian Peso",
        "displayName-count-one": "Bolivian peso",
        "displayName-count-other": "Bolivian pesos",
        symbol: "BOP"
      },
      BOV: {
        displayName: "Bolivian Mvdol",
        "displayName-count-one": "Bolivian mvdol",
        "displayName-count-other": "Bolivian mvdols",
        symbol: "BOV"
      },
      BRB: {
        displayName: "Brazilian New Cruzeiro (1967–1986)",
        "displayName-count-one": "Brazilian new cruzeiro (1967–1986)",
        "displayName-count-other": "Brazilian new cruzeiros (1967–1986)",
        symbol: "BRB"
      },
      BRC: {
        displayName: "Brazilian Cruzado (1986–1989)",
        "displayName-count-one": "Brazilian cruzado (1986–1989)",
        "displayName-count-other": "Brazilian cruzados (1986–1989)",
        symbol: "BRC"
      },
      BRE: {
        displayName: "Brazilian Cruzeiro (1990–1993)",
        "displayName-count-one": "Brazilian cruzeiro (1990–1993)",
        "displayName-count-other": "Brazilian cruzeiros (1990–1993)",
        symbol: "BRE"
      },
      BRL: {
        displayName: "Brazilian Real",
        "displayName-count-one": "Brazilian real",
        "displayName-count-other": "Brazilian reals",
        symbol: "R$",
        "symbol-alt-narrow": "R$"
      },
      BRN: {
        displayName: "Brazilian New Cruzado (1989–1990)",
        "displayName-count-one": "Brazilian new cruzado (1989–1990)",
        "displayName-count-other": "Brazilian new cruzados (1989–1990)",
        symbol: "BRN"
      },
      BRR: {
        displayName: "Brazilian Cruzeiro (1993–1994)",
        "displayName-count-one": "Brazilian cruzeiro (1993–1994)",
        "displayName-count-other": "Brazilian cruzeiros (1993–1994)",
        symbol: "BRR"
      },
      BRZ: {
        displayName: "Brazilian Cruzeiro (1942–1967)",
        "displayName-count-one": "Brazilian cruzeiro (1942–1967)",
        "displayName-count-other": "Brazilian cruzeiros (1942–1967)",
        symbol: "BRZ"
      },
      BSD: {
        displayName: "Bahamian Dollar",
        "displayName-count-one": "Bahamian dollar",
        "displayName-count-other": "Bahamian dollars",
        symbol: "BSD",
        "symbol-alt-narrow": "$"
      },
      BTN: {
        displayName: "Bhutanese Ngultrum",
        "displayName-count-one": "Bhutanese ngultrum",
        "displayName-count-other": "Bhutanese ngultrums",
        symbol: "BTN"
      },
      BUK: {
        displayName: "Burmese Kyat",
        "displayName-count-one": "Burmese kyat",
        "displayName-count-other": "Burmese kyats",
        symbol: "BUK"
      },
      BWP: {
        displayName: "Botswanan Pula",
        "displayName-count-one": "Botswanan pula",
        "displayName-count-other": "Botswanan pulas",
        symbol: "BWP",
        "symbol-alt-narrow": "P"
      },
      BYB: {
        displayName: "Belarusian New Rouble (1994–1999)",
        "displayName-count-one": "Belarusian new rouble (1994–1999)",
        "displayName-count-other": "Belarusian new roubles (1994–1999)",
        symbol: "BYB"
      },
      BYN: {
        displayName: "Belarusian Rouble",
        "displayName-count-one": "Belarusian rouble",
        "displayName-count-other": "Belarusian roubles",
        symbol: "BYN"
      },
      BYR: {
        displayName: "Belarusian Rouble (2000–2016)",
        "displayName-count-one": "Belarusian rouble (2000–2016)",
        "displayName-count-other": "Belarusian roubles (2000–2016)",
        symbol: "BYR"
      },
      BZD: {
        displayName: "Belize Dollar",
        "displayName-count-one": "Belize dollar",
        "displayName-count-other": "Belize dollars",
        symbol: "BZD",
        "symbol-alt-narrow": "$"
      },
      CAD: {
        displayName: "Canadian Dollar",
        "displayName-count-one": "Canadian dollar",
        "displayName-count-other": "Canadian dollars",
        symbol: "CA$",
        "symbol-alt-narrow": "$"
      },
      CDF: {
        displayName: "Congolese Franc",
        "displayName-count-one": "Congolese franc",
        "displayName-count-other": "Congolese francs",
        symbol: "CDF"
      },
      CHE: {
        displayName: "WIR Euro",
        "displayName-count-one": "WIR euro",
        "displayName-count-other": "WIR euros",
        symbol: "CHE"
      },
      CHF: {
        displayName: "Swiss Franc",
        "displayName-count-one": "Swiss franc",
        "displayName-count-other": "Swiss francs",
        symbol: "CHF"
      },
      CHW: {
        displayName: "WIR Franc",
        "displayName-count-one": "WIR franc",
        "displayName-count-other": "WIR francs",
        symbol: "CHW"
      },
      CLE: {
        displayName: "Chilean Escudo",
        "displayName-count-one": "Chilean escudo",
        "displayName-count-other": "Chilean escudos",
        symbol: "CLE"
      },
      CLF: {
        displayName: "Chilean Unit of Account (UF)",
        "displayName-count-one": "Chilean unit of account (UF)",
        "displayName-count-other": "Chilean units of account (UF)",
        symbol: "CLF"
      },
      CLP: {
        displayName: "Chilean Peso",
        "displayName-count-one": "Chilean peso",
        "displayName-count-other": "Chilean pesos",
        symbol: "CLP",
        "symbol-alt-narrow": "$"
      },
      CNH: {
        displayName: "Chinese Yuan (offshore)",
        "displayName-count-one": "Chinese yuan (offshore)",
        "displayName-count-other": "Chinese yuan (offshore)",
        symbol: "CNH"
      },
      CNX: {
        displayName: "Chinese People’s Bank Dollar",
        "displayName-count-one": "Chinese People’s Bank dollar",
        "displayName-count-other": "Chinese People’s Bank dollars",
        symbol: "CNX"
      },
      CNY: {
        displayName: "Chinese Yuan",
        "displayName-count-one": "Chinese yuan",
        "displayName-count-other": "Chinese yuan",
        symbol: "CN¥",
        "symbol-alt-narrow": "¥"
      },
      COP: {
        displayName: "Colombian Peso",
        "displayName-count-one": "Colombian peso",
        "displayName-count-other": "Colombian pesos",
        symbol: "COP",
        "symbol-alt-narrow": "$"
      },
      COU: {
        displayName: "Colombian Real Value Unit",
        "displayName-count-one": "Colombian real value unit",
        "displayName-count-other": "Colombian real value units",
        symbol: "COU"
      },
      CRC: {
        displayName: "Costa Rican Colón",
        "displayName-count-one": "Costa Rican colón",
        "displayName-count-other": "Costa Rican colóns",
        symbol: "CRC",
        "symbol-alt-narrow": "₡"
      },
      CSD: {
        displayName: "Serbian Dinar (2002–2006)",
        "displayName-count-one": "Serbian dinar (2002–2006)",
        "displayName-count-other": "Serbian dinars (2002–2006)",
        symbol: "CSD"
      },
      CSK: {
        displayName: "Czechoslovak Hard Koruna",
        "displayName-count-one": "Czechoslovak hard koruna",
        "displayName-count-other": "Czechoslovak hard korunas",
        symbol: "CSK"
      },
      CUC: {
        displayName: "Cuban Convertible Peso",
        "displayName-count-one": "Cuban convertible peso",
        "displayName-count-other": "Cuban convertible pesos",
        symbol: "CUC",
        "symbol-alt-narrow": "$"
      },
      CUP: {
        displayName: "Cuban Peso",
        "displayName-count-one": "Cuban peso",
        "displayName-count-other": "Cuban pesos",
        symbol: "CUP",
        "symbol-alt-narrow": "$"
      },
      CVE: {
        displayName: "Cape Verdean Escudo",
        "displayName-count-one": "Cape Verdean escudo",
        "displayName-count-other": "Cape Verdean escudos",
        symbol: "CVE"
      },
      CYP: {
        displayName: "Cypriot Pound",
        "displayName-count-one": "Cypriot pound",
        "displayName-count-other": "Cypriot pounds",
        symbol: "CYP"
      },
      CZK: {
        displayName: "Czech Koruna",
        "displayName-count-one": "Czech koruna",
        "displayName-count-other": "Czech korunas",
        symbol: "CZK",
        "symbol-alt-narrow": "Kč"
      },
      DDM: {
        displayName: "East German Mark",
        "displayName-count-one": "East German mark",
        "displayName-count-other": "East German marks",
        symbol: "DDM"
      },
      DEM: {
        displayName: "German Mark",
        "displayName-count-one": "German mark",
        "displayName-count-other": "German marks",
        symbol: "DEM"
      },
      DJF: {
        displayName: "Djiboutian Franc",
        "displayName-count-one": "Djiboutian franc",
        "displayName-count-other": "Djiboutian francs",
        symbol: "DJF"
      },
      DKK: {
        displayName: "Danish Krone",
        "displayName-count-one": "Danish krone",
        "displayName-count-other": "Danish kroner",
        symbol: "DKK",
        "symbol-alt-narrow": "kr"
      },
      DOP: {
        displayName: "Dominican Peso",
        "displayName-count-one": "Dominican peso",
        "displayName-count-other": "Dominican pesos",
        symbol: "DOP",
        "symbol-alt-narrow": "$"
      },
      DZD: {
        displayName: "Algerian Dinar",
        "displayName-count-one": "Algerian dinar",
        "displayName-count-other": "Algerian dinars",
        symbol: "DZD"
      },
      ECS: {
        displayName: "Ecuadorian Sucre",
        "displayName-count-one": "Ecuadorian sucre",
        "displayName-count-other": "Ecuadorian sucres",
        symbol: "ECS"
      },
      ECV: {
        displayName: "Ecuadorian Unit of Constant Value",
        "displayName-count-one": "Ecuadorian unit of constant value",
        "displayName-count-other": "Ecuadorian units of constant value",
        symbol: "ECV"
      },
      EEK: {
        displayName: "Estonian Kroon",
        "displayName-count-one": "Estonian kroon",
        "displayName-count-other": "Estonian kroons",
        symbol: "EEK"
      },
      EGP: {
        displayName: "Egyptian Pound",
        "displayName-count-one": "Egyptian pound",
        "displayName-count-other": "Egyptian pounds",
        symbol: "EGP",
        "symbol-alt-narrow": "E£"
      },
      ERN: {
        displayName: "Eritrean Nakfa",
        "displayName-count-one": "Eritrean nakfa",
        "displayName-count-other": "Eritrean nakfas",
        symbol: "ERN"
      },
      ESA: {
        displayName: "Spanish Peseta (A account)",
        "displayName-count-one": "Spanish peseta (A account)",
        "displayName-count-other": "Spanish pesetas (A account)",
        symbol: "ESA"
      },
      ESB: {
        displayName: "Spanish Peseta (convertible account)",
        "displayName-count-one": "Spanish peseta (convertible account)",
        "displayName-count-other": "Spanish pesetas (convertible account)",
        symbol: "ESB"
      },
      ESP: {
        displayName: "Spanish Peseta",
        "displayName-count-one": "Spanish peseta",
        "displayName-count-other": "Spanish pesetas",
        symbol: "ESP",
        "symbol-alt-narrow": "₧"
      },
      ETB: {
        displayName: "Ethiopian Birr",
        "displayName-count-one": "Ethiopian birr",
        "displayName-count-other": "Ethiopian birr",
        symbol: "ETB"
      },
      EUR: {
        displayName: "Euro",
        "displayName-count-one": "euro",
        "displayName-count-other": "euros",
        symbol: "€",
        "symbol-alt-narrow": "€"
      },
      FIM: {
        displayName: "Finnish Markka",
        "displayName-count-one": "Finnish markka",
        "displayName-count-other": "Finnish markkas",
        symbol: "FIM"
      },
      FJD: {
        displayName: "Fijian Dollar",
        "displayName-count-one": "Fijian dollar",
        "displayName-count-other": "Fijian dollars",
        symbol: "FJD",
        "symbol-alt-narrow": "$"
      },
      FKP: {
        displayName: "Falkland Islands Pound",
        "displayName-count-one": "Falkland Islands pound",
        "displayName-count-other": "Falkland Islands pounds",
        symbol: "FKP",
        "symbol-alt-narrow": "£"
      },
      FRF: {
        displayName: "French Franc",
        "displayName-count-one": "French franc",
        "displayName-count-other": "French francs",
        symbol: "FRF"
      },
      GBP: {
        displayName: "British Pound",
        "displayName-count-one": "British pound",
        "displayName-count-other": "British pounds",
        symbol: "£",
        "symbol-alt-narrow": "£"
      },
      GEK: {
        displayName: "Georgian Kupon Larit",
        "displayName-count-one": "Georgian kupon larit",
        "displayName-count-other": "Georgian kupon larits",
        symbol: "GEK"
      },
      GEL: {
        displayName: "Georgian Lari",
        "displayName-count-one": "Georgian lari",
        "displayName-count-other": "Georgian laris",
        symbol: "GEL",
        "symbol-alt-narrow": "₾"
      },
      GHC: {
        displayName: "Ghanaian Cedi (1979–2007)",
        "displayName-count-one": "Ghanaian cedi (1979–2007)",
        "displayName-count-other": "Ghanaian cedis (1979–2007)",
        symbol: "GHC"
      },
      GHS: {
        displayName: "Ghanaian Cedi",
        "displayName-count-one": "Ghanaian cedi",
        "displayName-count-other": "Ghanaian cedis",
        symbol: "GHS",
        "symbol-alt-narrow": "GH₵"
      },
      GIP: {
        displayName: "Gibraltar Pound",
        "displayName-count-one": "Gibraltar pound",
        "displayName-count-other": "Gibraltar pounds",
        symbol: "GIP",
        "symbol-alt-narrow": "£"
      },
      GMD: {
        displayName: "Gambian Dalasi",
        "displayName-count-one": "Gambian dalasi",
        "displayName-count-other": "Gambian dalasis",
        symbol: "GMD"
      },
      GNF: {
        displayName: "Guinean Franc",
        "displayName-count-one": "Guinean franc",
        "displayName-count-other": "Guinean francs",
        symbol: "GNF",
        "symbol-alt-narrow": "FG"
      },
      GNS: {
        displayName: "Guinean Syli",
        "displayName-count-one": "Guinean syli",
        "displayName-count-other": "Guinean sylis",
        symbol: "GNS"
      },
      GQE: {
        displayName: "Equatorial Guinean Ekwele",
        "displayName-count-one": "Equatorial Guinean ekwele",
        "displayName-count-other": "Equatorial Guinean ekwele",
        symbol: "GQE"
      },
      GRD: {
        displayName: "Greek Drachma",
        "displayName-count-one": "Greek drachma",
        "displayName-count-other": "Greek drachmas",
        symbol: "GRD"
      },
      GTQ: {
        displayName: "Guatemalan Quetzal",
        "displayName-count-one": "Guatemalan quetzal",
        "displayName-count-other": "Guatemalan quetzals",
        symbol: "GTQ",
        "symbol-alt-narrow": "Q"
      },
      GWE: {
        displayName: "Portuguese Guinea Escudo",
        "displayName-count-one": "Portuguese Guinea escudo",
        "displayName-count-other": "Portuguese Guinea escudos",
        symbol: "GWE"
      },
      GWP: {
        displayName: "Guinea-Bissau Peso",
        "displayName-count-one": "Guinea-Bissau peso",
        "displayName-count-other": "Guinea-Bissau pesos",
        symbol: "GWP"
      },
      GYD: {
        displayName: "Guyanaese Dollar",
        "displayName-count-one": "Guyanaese dollar",
        "displayName-count-other": "Guyanaese dollars",
        symbol: "GYD",
        "symbol-alt-narrow": "$"
      },
      HKD: {
        displayName: "Hong Kong Dollar",
        "displayName-count-one": "Hong Kong dollar",
        "displayName-count-other": "Hong Kong dollars",
        symbol: "HK$",
        "symbol-alt-narrow": "$"
      },
      HNL: {
        displayName: "Honduran Lempira",
        "displayName-count-one": "Honduran lempira",
        "displayName-count-other": "Honduran lempiras",
        symbol: "HNL",
        "symbol-alt-narrow": "L"
      },
      HRD: {
        displayName: "Croatian Dinar",
        "displayName-count-one": "Croatian dinar",
        "displayName-count-other": "Croatian dinars",
        symbol: "HRD"
      },
      HRK: {
        displayName: "Croatian Kuna",
        "displayName-count-one": "Croatian kuna",
        "displayName-count-other": "Croatian kunas",
        symbol: "HRK",
        "symbol-alt-narrow": "kn"
      },
      HTG: {
        displayName: "Haitian Gourde",
        "displayName-count-one": "Haitian gourde",
        "displayName-count-other": "Haitian gourdes",
        symbol: "HTG"
      },
      HUF: {
        displayName: "Hungarian Forint",
        "displayName-count-one": "Hungarian forint",
        "displayName-count-other": "Hungarian forints",
        symbol: "HUF",
        "symbol-alt-narrow": "Ft"
      },
      IDR: {
        displayName: "Indonesian Rupiah",
        "displayName-count-one": "Indonesian rupiah",
        "displayName-count-other": "Indonesian rupiahs",
        symbol: "IDR",
        "symbol-alt-narrow": "Rp"
      },
      IEP: {
        displayName: "Irish Pound",
        "displayName-count-one": "Irish pound",
        "displayName-count-other": "Irish pounds",
        symbol: "IEP"
      },
      ILP: {
        displayName: "Israeli Pound",
        "displayName-count-one": "Israeli pound",
        "displayName-count-other": "Israeli pounds",
        symbol: "ILP"
      },
      ILR: {
        displayName: "Israeli Shekel (1980–1985)",
        "displayName-count-one": "Israeli shekel (1980–1985)",
        "displayName-count-other": "Israeli shekels (1980–1985)",
        symbol: "ILR"
      },
      ILS: {
        displayName: "Israeli New Shekel",
        "displayName-count-one": "Israeli new shekel",
        "displayName-count-other": "Israeli new shekels",
        symbol: "₪",
        "symbol-alt-narrow": "₪"
      },
      INR: {
        displayName: "Indian Rupee",
        "displayName-count-one": "Indian rupee",
        "displayName-count-other": "Indian rupees",
        symbol: "₹",
        "symbol-alt-narrow": "₹"
      },
      IQD: {
        displayName: "Iraqi Dinar",
        "displayName-count-one": "Iraqi dinar",
        "displayName-count-other": "Iraqi dinars",
        symbol: "IQD"
      },
      IRR: {
        displayName: "Iranian Rial",
        "displayName-count-one": "Iranian rial",
        "displayName-count-other": "Iranian rials",
        symbol: "IRR"
      },
      ISJ: {
        displayName: "Icelandic Króna (1918–1981)",
        "displayName-count-one": "Icelandic króna (1918–1981)",
        "displayName-count-other": "Icelandic krónur (1918–1981)",
        symbol: "ISJ"
      },
      ISK: {
        displayName: "Icelandic Króna",
        "displayName-count-one": "Icelandic króna",
        "displayName-count-other": "Icelandic krónur",
        symbol: "ISK",
        "symbol-alt-narrow": "kr"
      },
      ITL: {
        displayName: "Italian Lira",
        "displayName-count-one": "Italian lira",
        "displayName-count-other": "Italian liras",
        symbol: "ITL"
      },
      JMD: {
        displayName: "Jamaican Dollar",
        "displayName-count-one": "Jamaican dollar",
        "displayName-count-other": "Jamaican dollars",
        symbol: "JMD",
        "symbol-alt-narrow": "$"
      },
      JOD: {
        displayName: "Jordanian Dinar",
        "displayName-count-one": "Jordanian dinar",
        "displayName-count-other": "Jordanian dinars",
        symbol: "JOD"
      },
      JPY: {
        displayName: "Japanese Yen",
        "displayName-count-one": "Japanese yen",
        "displayName-count-other": "Japanese yen",
        symbol: "JP¥",
        "symbol-alt-narrow": "¥"
      },
      KES: {
        displayName: "Kenyan Shilling",
        "displayName-count-one": "Kenyan shilling",
        "displayName-count-other": "Kenyan shillings",
        symbol: "KES"
      },
      KGS: {
        displayName: "Kyrgystani Som",
        "displayName-count-one": "Kyrgystani som",
        "displayName-count-other": "Kyrgystani soms",
        symbol: "KGS"
      },
      KHR: {
        displayName: "Cambodian Riel",
        "displayName-count-one": "Cambodian riel",
        "displayName-count-other": "Cambodian riels",
        symbol: "KHR",
        "symbol-alt-narrow": "៛"
      },
      KMF: {
        displayName: "Comorian Franc",
        "displayName-count-one": "Comorian franc",
        "displayName-count-other": "Comorian francs",
        symbol: "KMF",
        "symbol-alt-narrow": "CF"
      },
      KPW: {
        displayName: "North Korean Won",
        "displayName-count-one": "North Korean won",
        "displayName-count-other": "North Korean won",
        symbol: "KPW",
        "symbol-alt-narrow": "₩"
      },
      KRH: {
        displayName: "South Korean Hwan (1953–1962)",
        "displayName-count-one": "South Korean hwan (1953–1962)",
        "displayName-count-other": "South Korean hwan (1953–1962)",
        symbol: "KRH"
      },
      KRO: {
        displayName: "South Korean Won (1945–1953)",
        "displayName-count-one": "South Korean won (1945–1953)",
        "displayName-count-other": "South Korean won (1945–1953)",
        symbol: "KRO"
      },
      KRW: {
        displayName: "South Korean Won",
        "displayName-count-one": "South Korean won",
        "displayName-count-other": "South Korean won",
        symbol: "₩",
        "symbol-alt-narrow": "₩"
      },
      KWD: {
        displayName: "Kuwaiti Dinar",
        "displayName-count-one": "Kuwaiti dinar",
        "displayName-count-other": "Kuwaiti dinars",
        symbol: "KWD"
      },
      KYD: {
        displayName: "Cayman Islands Dollar",
        "displayName-count-one": "Cayman Islands dollar",
        "displayName-count-other": "Cayman Islands dollars",
        symbol: "KYD",
        "symbol-alt-narrow": "$"
      },
      KZT: {
        displayName: "Kazakhstani Tenge",
        "displayName-count-one": "Kazakhstani tenge",
        "displayName-count-other": "Kazakhstani tenges",
        symbol: "KZT",
        "symbol-alt-narrow": "₸"
      },
      LAK: {
        displayName: "Laotian Kip",
        "displayName-count-one": "Laotian kip",
        "displayName-count-other": "Laotian kips",
        symbol: "LAK",
        "symbol-alt-narrow": "₭"
      },
      LBP: {
        displayName: "Lebanese Pound",
        "displayName-count-one": "Lebanese pound",
        "displayName-count-other": "Lebanese pounds",
        symbol: "LBP",
        "symbol-alt-narrow": "L£"
      },
      LKR: {
        displayName: "Sri Lankan Rupee",
        "displayName-count-one": "Sri Lankan rupee",
        "displayName-count-other": "Sri Lankan rupees",
        symbol: "LKR",
        "symbol-alt-narrow": "Rs"
      },
      LRD: {
        displayName: "Liberian Dollar",
        "displayName-count-one": "Liberian dollar",
        "displayName-count-other": "Liberian dollars",
        symbol: "LRD",
        "symbol-alt-narrow": "$"
      },
      LSL: {
        displayName: "Lesotho Loti",
        "displayName-count-one": "Lesotho loti",
        "displayName-count-other": "Lesotho maloti",
        symbol: "LSL"
      },
      LTL: {
        displayName: "Lithuanian Litas",
        "displayName-count-one": "Lithuanian litas",
        "displayName-count-other": "Lithuanian litai",
        symbol: "LTL",
        "symbol-alt-narrow": "Lt"
      },
      LTT: {
        displayName: "Lithuanian Talonas",
        "displayName-count-one": "Lithuanian talonas",
        "displayName-count-other": "Lithuanian talonases",
        symbol: "LTT"
      },
      LUC: {
        displayName: "Luxembourgian Convertible Franc",
        "displayName-count-one": "Luxembourgian convertible franc",
        "displayName-count-other": "Luxembourgian convertible francs",
        symbol: "LUC"
      },
      LUF: {
        displayName: "Luxembourgian Franc",
        "displayName-count-one": "Luxembourgian franc",
        "displayName-count-other": "Luxembourgian francs",
        symbol: "LUF"
      },
      LUL: {
        displayName: "Luxembourg Financial Franc",
        "displayName-count-one": "Luxembourg financial franc",
        "displayName-count-other": "Luxembourg financial francs",
        symbol: "LUL"
      },
      LVL: {
        displayName: "Latvian Lats",
        "displayName-count-one": "Latvian lats",
        "displayName-count-other": "Latvian lati",
        symbol: "LVL",
        "symbol-alt-narrow": "Ls"
      },
      LVR: {
        displayName: "Latvian Rouble",
        "displayName-count-one": "Latvian rouble",
        "displayName-count-other": "Latvian roubles",
        symbol: "LVR"
      },
      LYD: {
        displayName: "Libyan Dinar",
        "displayName-count-one": "Libyan dinar",
        "displayName-count-other": "Libyan dinars",
        symbol: "LYD"
      },
      MAD: {
        displayName: "Moroccan Dirham",
        "displayName-count-one": "Moroccan dirham",
        "displayName-count-other": "Moroccan dirhams",
        symbol: "MAD"
      },
      MAF: {
        displayName: "Moroccan Franc",
        "displayName-count-one": "Moroccan franc",
        "displayName-count-other": "Moroccan francs",
        symbol: "MAF"
      },
      MCF: {
        displayName: "Monegasque Franc",
        "displayName-count-one": "Monegasque franc",
        "displayName-count-other": "Monegasque francs",
        symbol: "MCF"
      },
      MDC: {
        displayName: "Moldovan Cupon",
        "displayName-count-one": "Moldovan cupon",
        "displayName-count-other": "Moldovan cupon",
        symbol: "MDC"
      },
      MDL: {
        displayName: "Moldovan Leu",
        "displayName-count-one": "Moldovan leu",
        "displayName-count-other": "Moldovan lei",
        symbol: "MDL"
      },
      MGA: {
        displayName: "Malagasy Ariary",
        "displayName-count-one": "Malagasy ariary",
        "displayName-count-other": "Malagasy ariary",
        symbol: "MGA",
        "symbol-alt-narrow": "Ar"
      },
      MGF: {
        displayName: "Malagasy Franc",
        "displayName-count-one": "Malagasy franc",
        "displayName-count-other": "Malagasy francs",
        symbol: "MGF"
      },
      MKD: {
        displayName: "Macedonian Denar",
        "displayName-count-one": "Macedonian denar",
        "displayName-count-other": "Macedonian denari",
        symbol: "MKD"
      },
      MKN: {
        displayName: "Macedonian Denar (1992–1993)",
        "displayName-count-one": "Macedonian denar (1992–1993)",
        "displayName-count-other": "Macedonian denari (1992–1993)",
        symbol: "MKN"
      },
      MLF: {
        displayName: "Malian Franc",
        "displayName-count-one": "Malian franc",
        "displayName-count-other": "Malian francs",
        symbol: "MLF"
      },
      MMK: {
        displayName: "Myanmar Kyat",
        "displayName-count-one": "Myanmar kyat",
        "displayName-count-other": "Myanmar kyats",
        symbol: "MMK",
        "symbol-alt-narrow": "K"
      },
      MNT: {
        displayName: "Mongolian Tugrik",
        "displayName-count-one": "Mongolian tugrik",
        "displayName-count-other": "Mongolian tugriks",
        symbol: "MNT",
        "symbol-alt-narrow": "₮"
      },
      MOP: {
        displayName: "Macanese Pataca",
        "displayName-count-one": "Macanese pataca",
        "displayName-count-other": "Macanese patacas",
        symbol: "MOP"
      },
      MRO: {
        displayName: "Mauritanian Ouguiya (1973–2017)",
        "displayName-count-one": "Mauritanian ouguiya (1973–2017)",
        "displayName-count-other": "Mauritanian ouguiyas (1973–2017)",
        symbol: "MRO"
      },
      MRU: {
        displayName: "Mauritanian Ouguiya",
        "displayName-count-one": "Mauritanian ouguiya",
        "displayName-count-other": "Mauritanian ouguiyas",
        symbol: "MRU"
      },
      MTL: {
        displayName: "Maltese Lira",
        "displayName-count-one": "Maltese lira",
        "displayName-count-other": "Maltese lira",
        symbol: "MTL"
      },
      MTP: {
        displayName: "Maltese Pound",
        "displayName-count-one": "Maltese pound",
        "displayName-count-other": "Maltese pounds",
        symbol: "MTP"
      },
      MUR: {
        displayName: "Mauritian Rupee",
        "displayName-count-one": "Mauritian rupee",
        "displayName-count-other": "Mauritian rupees",
        symbol: "MUR",
        "symbol-alt-narrow": "Rs"
      },
      MVP: {
        displayName: "Maldivian Rupee (1947–1981)",
        "displayName-count-one": "Maldivian rupee (1947–1981)",
        "displayName-count-other": "Maldivian rupees (1947–1981)",
        symbol: "MVP"
      },
      MVR: {
        displayName: "Maldivian Rufiyaa",
        "displayName-count-one": "Maldivian rufiyaa",
        "displayName-count-other": "Maldivian rufiyaa",
        symbol: "MVR"
      },
      MWK: {
        displayName: "Malawian Kwacha",
        "displayName-count-one": "Malawian kwacha",
        "displayName-count-other": "Malawian kwachas",
        symbol: "MWK"
      },
      MXN: {
        displayName: "Mexican Peso",
        "displayName-count-one": "Mexican peso",
        "displayName-count-other": "Mexican pesos",
        symbol: "MX$",
        "symbol-alt-narrow": "$"
      },
      MXP: {
        displayName: "Mexican Silver Peso (1861–1992)",
        "displayName-count-one": "Mexican silver peso (1861–1992)",
        "displayName-count-other": "Mexican silver pesos (1861–1992)",
        symbol: "MXP"
      },
      MXV: {
        displayName: "Mexican Investment Unit",
        "displayName-count-one": "Mexican investment unit",
        "displayName-count-other": "Mexican investment units",
        symbol: "MXV"
      },
      MYR: {
        displayName: "Malaysian Ringgit",
        "displayName-count-one": "Malaysian ringgit",
        "displayName-count-other": "Malaysian ringgits",
        symbol: "MYR",
        "symbol-alt-narrow": "RM"
      },
      MZE: {
        displayName: "Mozambican Escudo",
        "displayName-count-one": "Mozambican escudo",
        "displayName-count-other": "Mozambican escudos",
        symbol: "MZE"
      },
      MZM: {
        displayName: "Mozambican Metical (1980–2006)",
        "displayName-count-one": "Mozambican metical (1980–2006)",
        "displayName-count-other": "Mozambican meticals (1980–2006)",
        symbol: "MZM"
      },
      MZN: {
        displayName: "Mozambican Metical",
        "displayName-count-one": "Mozambican metical",
        "displayName-count-other": "Mozambican meticals",
        symbol: "MZN"
      },
      NAD: {
        displayName: "Namibian Dollar",
        "displayName-count-one": "Namibian dollar",
        "displayName-count-other": "Namibian dollars",
        symbol: "NAD",
        "symbol-alt-narrow": "$"
      },
      NGN: {
        displayName: "Nigerian Naira",
        "displayName-count-one": "Nigerian naira",
        "displayName-count-other": "Nigerian nairas",
        symbol: "NGN",
        "symbol-alt-narrow": "₦"
      },
      NIC: {
        displayName: "Nicaraguan Córdoba (1988–1991)",
        "displayName-count-one": "Nicaraguan córdoba (1988–1991)",
        "displayName-count-other": "Nicaraguan córdobas (1988–1991)",
        symbol: "NIC"
      },
      NIO: {
        displayName: "Nicaraguan Córdoba",
        "displayName-count-one": "Nicaraguan córdoba",
        "displayName-count-other": "Nicaraguan córdobas",
        symbol: "NIO",
        "symbol-alt-narrow": "C$"
      },
      NLG: {
        displayName: "Dutch Guilder",
        "displayName-count-one": "Dutch guilder",
        "displayName-count-other": "Dutch guilders",
        symbol: "NLG"
      },
      NOK: {
        displayName: "Norwegian Krone",
        "displayName-count-one": "Norwegian krone",
        "displayName-count-other": "Norwegian kroner",
        symbol: "NOK",
        "symbol-alt-narrow": "kr"
      },
      NPR: {
        displayName: "Nepalese Rupee",
        "displayName-count-one": "Nepalese rupee",
        "displayName-count-other": "Nepalese rupees",
        symbol: "NPR",
        "symbol-alt-narrow": "Rs"
      },
      NZD: {
        displayName: "New Zealand Dollar",
        "displayName-count-one": "New Zealand dollar",
        "displayName-count-other": "New Zealand dollars",
        symbol: "NZ$",
        "symbol-alt-narrow": "$"
      },
      OMR: {
        displayName: "Omani Rial",
        "displayName-count-one": "Omani rial",
        "displayName-count-other": "Omani rials",
        symbol: "OMR"
      },
      PAB: {
        displayName: "Panamanian Balboa",
        "displayName-count-one": "Panamanian balboa",
        "displayName-count-other": "Panamanian balboas",
        symbol: "PAB"
      },
      PEI: {
        displayName: "Peruvian Inti",
        "displayName-count-one": "Peruvian inti",
        "displayName-count-other": "Peruvian intis",
        symbol: "PEI"
      },
      PEN: {
        displayName: "Peruvian Sol",
        "displayName-count-one": "Peruvian sol",
        "displayName-count-other": "Peruvian soles",
        symbol: "PEN"
      },
      PES: {
        displayName: "Peruvian Sol (1863–1965)",
        "displayName-count-one": "Peruvian sol (1863–1965)",
        "displayName-count-other": "Peruvian soles (1863–1965)",
        symbol: "PES"
      },
      PGK: {
        displayName: "Papua New Guinean Kina",
        "displayName-count-one": "Papua New Guinean kina",
        "displayName-count-other": "Papua New Guinean kina",
        symbol: "PGK"
      },
      PHP: {
        displayName: "Philippine Peso",
        "displayName-count-one": "Philippine peso",
        "displayName-count-other": "Philippine pesos",
        symbol: "₱",
        "symbol-alt-narrow": "₱"
      },
      PKR: {
        displayName: "Pakistani Rupee",
        "displayName-count-one": "Pakistani rupee",
        "displayName-count-other": "Pakistani rupees",
        symbol: "PKR",
        "symbol-alt-narrow": "Rs"
      },
      PLN: {
        displayName: "Polish Zloty",
        "displayName-count-one": "Polish zloty",
        "displayName-count-other": "Polish zlotys",
        symbol: "PLN",
        "symbol-alt-narrow": "zł"
      },
      PLZ: {
        displayName: "Polish Zloty (1950–1995)",
        "displayName-count-one": "Polish zloty (PLZ)",
        "displayName-count-other": "Polish zlotys (PLZ)",
        symbol: "PLZ"
      },
      PTE: {
        displayName: "Portuguese Escudo",
        "displayName-count-one": "Portuguese escudo",
        "displayName-count-other": "Portuguese escudos",
        symbol: "PTE"
      },
      PYG: {
        displayName: "Paraguayan Guarani",
        "displayName-count-one": "Paraguayan guarani",
        "displayName-count-other": "Paraguayan guaranis",
        symbol: "PYG",
        "symbol-alt-narrow": "₲"
      },
      QAR: {
        displayName: "Qatari Rial",
        "displayName-count-one": "Qatari rial",
        "displayName-count-other": "Qatari rials",
        symbol: "QAR"
      },
      RHD: {
        displayName: "Rhodesian Dollar",
        "displayName-count-one": "Rhodesian dollar",
        "displayName-count-other": "Rhodesian dollars",
        symbol: "RHD"
      },
      ROL: {
        displayName: "Romanian Leu (1952–2006)",
        "displayName-count-one": "Romanian leu (1952–2006)",
        "displayName-count-other": "Romanian Lei (1952–2006)",
        symbol: "ROL"
      },
      RON: {
        displayName: "Romanian Leu",
        "displayName-count-one": "Romanian leu",
        "displayName-count-other": "Romanian lei",
        symbol: "RON",
        "symbol-alt-narrow": "lei"
      },
      RSD: {
        displayName: "Serbian Dinar",
        "displayName-count-one": "Serbian dinar",
        "displayName-count-other": "Serbian dinars",
        symbol: "RSD"
      },
      RUB: {
        displayName: "Russian Rouble",
        "displayName-count-one": "Russian rouble",
        "displayName-count-other": "Russian roubles",
        symbol: "RUB",
        "symbol-alt-narrow": "₽"
      },
      RUR: {
        displayName: "Russian Rouble (1991–1998)",
        "displayName-count-one": "Russian rouble (1991–1998)",
        "displayName-count-other": "Russian roubles (1991–1998)",
        symbol: "RUR"
      },
      RWF: {
        displayName: "Rwandan Franc",
        "displayName-count-one": "Rwandan franc",
        "displayName-count-other": "Rwandan francs",
        symbol: "RWF",
        "symbol-alt-narrow": "RF"
      },
      SAR: {
        displayName: "Saudi Riyal",
        "displayName-count-one": "Saudi riyal",
        "displayName-count-other": "Saudi riyals",
        symbol: "SAR"
      },
      SBD: {
        displayName: "Solomon Islands Dollar",
        "displayName-count-one": "Solomon Islands dollar",
        "displayName-count-other": "Solomon Islands dollars",
        symbol: "SBD",
        "symbol-alt-narrow": "$"
      },
      SCR: {
        displayName: "Seychellois Rupee",
        "displayName-count-one": "Seychellois rupee",
        "displayName-count-other": "Seychellois rupees",
        symbol: "SCR"
      },
      SDD: {
        displayName: "Sudanese Dinar (1992–2007)",
        "displayName-count-one": "Sudanese dinar (1992–2007)",
        "displayName-count-other": "Sudanese dinars (1992–2007)",
        symbol: "SDD"
      },
      SDG: {
        displayName: "Sudanese Pound",
        "displayName-count-one": "Sudanese pound",
        "displayName-count-other": "Sudanese pounds",
        symbol: "SDG"
      },
      SDP: {
        displayName: "Sudanese Pound (1957–1998)",
        "displayName-count-one": "Sudanese pound (1957–1998)",
        "displayName-count-other": "Sudanese pounds (1957–1998)",
        symbol: "SDP"
      },
      SEK: {
        displayName: "Swedish Krona",
        "displayName-count-one": "Swedish krona",
        "displayName-count-other": "Swedish kronor",
        symbol: "SEK",
        "symbol-alt-narrow": "kr"
      },
      SGD: {
        displayName: "Singapore Dollar",
        "displayName-count-one": "Singapore dollar",
        "displayName-count-other": "Singapore dollars",
        symbol: "SGD",
        "symbol-alt-narrow": "$"
      },
      SHP: {
        displayName: "St Helena Pound",
        "displayName-count-one": "St Helena pound",
        "displayName-count-other": "St Helena pounds",
        symbol: "SHP",
        "symbol-alt-narrow": "£"
      },
      SIT: {
        displayName: "Slovenian Tolar",
        "displayName-count-one": "Slovenian tolar",
        "displayName-count-other": "Slovenian tolars",
        symbol: "SIT"
      },
      SKK: {
        displayName: "Slovak Koruna",
        "displayName-count-one": "Slovak koruna",
        "displayName-count-other": "Slovak korunas",
        symbol: "SKK"
      },
      SLE: {
        displayName: "Sierra Leonean New Leone",
        "displayName-count-one": "Sierra Leonean new leone",
        "displayName-count-other": "Sierra Leonean new leones",
        symbol: "SLE"
      },
      SLL: {
        displayName: "Sierra Leonean Leone",
        "displayName-count-one": "Sierra Leonean leone",
        "displayName-count-other": "Sierra Leonean leones",
        symbol: "SLL"
      },
      SOS: {
        displayName: "Somali Shilling",
        "displayName-count-one": "Somali shilling",
        "displayName-count-other": "Somali shillings",
        symbol: "SOS"
      },
      SRD: {
        displayName: "Surinamese Dollar",
        "displayName-count-one": "Surinamese dollar",
        "displayName-count-other": "Surinamese dollars",
        symbol: "SRD",
        "symbol-alt-narrow": "$"
      },
      SRG: {
        displayName: "Surinamese Guilder",
        "displayName-count-one": "Surinamese guilder",
        "displayName-count-other": "Surinamese guilders",
        symbol: "SRG"
      },
      SSP: {
        displayName: "South Sudanese Pound",
        "displayName-count-one": "South Sudanese pound",
        "displayName-count-other": "South Sudanese pounds",
        symbol: "SSP",
        "symbol-alt-narrow": "£"
      },
      STD: {
        displayName: "São Tomé & Príncipe Dobra (1977–2017)",
        "displayName-count-one": "São Tomé & Príncipe dobra (1977–2017)",
        "displayName-count-other": "São Tomé & Príncipe dobras (1977–2017)",
        symbol: "STD"
      },
      STN: {
        displayName: "São Tomé & Príncipe Dobra",
        "displayName-count-one": "São Tomé & Príncipe dobra",
        "displayName-count-other": "São Tomé & Príncipe dobras",
        symbol: "STN",
        "symbol-alt-narrow": "Db"
      },
      SUR: {
        displayName: "Soviet Rouble",
        "displayName-count-one": "Soviet rouble",
        "displayName-count-other": "Soviet roubles",
        symbol: "SUR"
      },
      SVC: {
        displayName: "Salvadoran Colón",
        "displayName-count-one": "Salvadoran colón",
        "displayName-count-other": "Salvadoran colones",
        symbol: "SVC"
      },
      SYP: {
        displayName: "Syrian Pound",
        "displayName-count-one": "Syrian pound",
        "displayName-count-other": "Syrian pounds",
        symbol: "SYP",
        "symbol-alt-narrow": "£"
      },
      SZL: {
        displayName: "Swazi Lilangeni",
        "displayName-count-one": "Swazi lilangeni",
        "displayName-count-other": "Swazi emalangeni",
        symbol: "SZL"
      },
      THB: {
        displayName: "Thai Baht",
        "displayName-count-one": "Thai baht",
        "displayName-count-other": "Thai baht",
        symbol: "THB",
        "symbol-alt-narrow": "฿"
      },
      TJR: {
        displayName: "Tajikistani Rouble",
        "displayName-count-one": "Tajikistani rouble",
        "displayName-count-other": "Tajikistani roubles",
        symbol: "TJR"
      },
      TJS: {
        displayName: "Tajikistani Somoni",
        "displayName-count-one": "Tajikistani somoni",
        "displayName-count-other": "Tajikistani somonis",
        symbol: "TJS"
      },
      TMM: {
        displayName: "Turkmenistani Manat (1993–2009)",
        "displayName-count-one": "Turkmenistani manat (1993–2009)",
        "displayName-count-other": "Turkmenistani manat (1993–2009)",
        symbol: "TMM"
      },
      TMT: {
        displayName: "Turkmenistani Manat",
        "displayName-count-one": "Turkmenistani manat",
        "displayName-count-other": "Turkmenistani manat",
        symbol: "TMT"
      },
      TND: {
        displayName: "Tunisian Dinar",
        "displayName-count-one": "Tunisian dinar",
        "displayName-count-other": "Tunisian dinars",
        symbol: "TND"
      },
      TOP: {
        displayName: "Tongan Paʻanga",
        "displayName-count-one": "Tongan paʻanga",
        "displayName-count-other": "Tongan paʻanga",
        symbol: "TOP",
        "symbol-alt-narrow": "T$"
      },
      TPE: {
        displayName: "Timorese Escudo",
        "displayName-count-one": "Timorese escudo",
        "displayName-count-other": "Timorese escudos",
        symbol: "TPE"
      },
      TRL: {
        displayName: "Turkish Lira (1922–2005)",
        "displayName-count-one": "Turkish lira (1922–2005)",
        "displayName-count-other": "Turkish Lira (1922–2005)",
        symbol: "TRL"
      },
      TRY: {
        displayName: "Turkish Lira",
        "displayName-count-one": "Turkish lira",
        "displayName-count-other": "Turkish Lira",
        symbol: "TRY",
        "symbol-alt-narrow": "₺",
        "symbol-alt-variant": "TL"
      },
      TTD: {
        displayName: "Trinidad & Tobago Dollar",
        "displayName-count-one": "Trinidad & Tobago dollar",
        "displayName-count-other": "Trinidad & Tobago dollars",
        symbol: "TTD",
        "symbol-alt-narrow": "$"
      },
      TWD: {
        displayName: "New Taiwan Dollar",
        "displayName-count-one": "New Taiwan dollar",
        "displayName-count-other": "New Taiwan dollars",
        symbol: "NT$",
        "symbol-alt-narrow": "$"
      },
      TZS: {
        displayName: "Tanzanian Shilling",
        "displayName-count-one": "Tanzanian shilling",
        "displayName-count-other": "Tanzanian shillings",
        symbol: "TZS"
      },
      UAH: {
        displayName: "Ukrainian Hryvnia",
        "displayName-count-one": "Ukrainian hryvnia",
        "displayName-count-other": "Ukrainian hryvnias",
        symbol: "UAH",
        "symbol-alt-narrow": "₴"
      },
      UAK: {
        displayName: "Ukrainian Karbovanets",
        "displayName-count-one": "Ukrainian karbovanets",
        "displayName-count-other": "Ukrainian karbovantsiv",
        symbol: "UAK"
      },
      UGS: {
        displayName: "Ugandan Shilling (1966–1987)",
        "displayName-count-one": "Ugandan shilling (1966–1987)",
        "displayName-count-other": "Ugandan shillings (1966–1987)",
        symbol: "UGS"
      },
      UGX: {
        displayName: "Ugandan Shilling",
        "displayName-count-one": "Ugandan shilling",
        "displayName-count-other": "Ugandan shillings",
        symbol: "UGX"
      },
      USD: {
        displayName: "US Dollar",
        "displayName-count-one": "US dollar",
        "displayName-count-other": "US dollars",
        symbol: "US$",
        "symbol-alt-narrow": "$"
      },
      USN: {
        displayName: "US Dollar (Next day)",
        "displayName-count-one": "US dollar (next day)",
        "displayName-count-other": "US dollars (next day)",
        symbol: "USN"
      },
      USS: {
        displayName: "US Dollar (Same day)",
        "displayName-count-one": "US dollar (same day)",
        "displayName-count-other": "US dollars (same day)",
        symbol: "USS"
      },
      UYI: {
        displayName: "Uruguayan Peso (Indexed Units)",
        "displayName-count-one": "Uruguayan peso (indexed units)",
        "displayName-count-other": "Uruguayan pesos (indexed units)",
        symbol: "UYI"
      },
      UYP: {
        displayName: "Uruguayan Peso (1975–1993)",
        "displayName-count-one": "Uruguayan peso (1975–1993)",
        "displayName-count-other": "Uruguayan pesos (1975–1993)",
        symbol: "UYP"
      },
      UYU: {
        displayName: "Uruguayan Peso",
        "displayName-count-one": "Uruguayan peso",
        "displayName-count-other": "Uruguayan pesos",
        symbol: "UYU",
        "symbol-alt-narrow": "$"
      },
      UYW: {
        displayName: "Uruguayan Nominal Wage Index Unit",
        "displayName-count-one": "Uruguayan nominal wage index unit",
        "displayName-count-other": "Uruguayan nominal wage index units",
        symbol: "UYW"
      },
      UZS: {
        displayName: "Uzbekistani Som",
        "displayName-count-one": "Uzbekistani som",
        "displayName-count-other": "Uzbekistani som",
        symbol: "UZS"
      },
      VEB: {
        displayName: "Venezuelan Bolívar (1871–2008)",
        "displayName-count-one": "Venezuelan bolívar (1871–2008)",
        "displayName-count-other": "Venezuelan bolívars (1871–2008)",
        symbol: "VEB"
      },
      VED: {
        displayName: "Bolívar Soberano",
        "displayName-count-one": "Bolívar Soberano",
        "displayName-count-other": "Bolívar Soberanos",
        symbol: "VED"
      },
      VEF: {
        displayName: "Venezuelan Bolívar (2008–2018)",
        "displayName-count-one": "Venezuelan bolívar (2008–2018)",
        "displayName-count-other": "Venezuelan bolívars (2008–2018)",
        symbol: "VEF",
        "symbol-alt-narrow": "Bs"
      },
      VES: {
        displayName: "Venezuelan Bolívar",
        "displayName-count-one": "Venezuelan bolívar",
        "displayName-count-other": "Venezuelan bolívars",
        symbol: "VES"
      },
      VND: {
        displayName: "Vietnamese Dong",
        "displayName-count-one": "Vietnamese dong",
        "displayName-count-other": "Vietnamese dong",
        symbol: "₫",
        "symbol-alt-narrow": "₫"
      },
      VNN: {
        displayName: "Vietnamese Dong (1978–1985)",
        "displayName-count-one": "Vietnamese dong (1978–1985)",
        "displayName-count-other": "Vietnamese dong (1978–1985)",
        symbol: "VNN"
      },
      VUV: {
        displayName: "Vanuatu Vatu",
        "displayName-count-one": "Vanuatu vatu",
        "displayName-count-other": "Vanuatu vatu",
        symbol: "VUV"
      },
      WST: {
        displayName: "Samoan Tala",
        "displayName-count-one": "Samoan tala",
        "displayName-count-other": "Samoan tala",
        symbol: "WST"
      },
      XAF: {
        displayName: "Central African CFA Franc",
        "displayName-count-one": "Central African CFA franc",
        "displayName-count-other": "Central African CFA francs",
        symbol: "FCFA"
      },
      XAG: {
        displayName: "Silver",
        "displayName-count-one": "troy ounce of silver",
        "displayName-count-other": "troy ounces of silver",
        symbol: "XAG"
      },
      XAU: {
        displayName: "Gold",
        "displayName-count-one": "troy ounce of gold",
        "displayName-count-other": "troy ounces of gold",
        symbol: "XAU"
      },
      XBA: {
        displayName: "European Composite Unit",
        "displayName-count-one": "European composite unit",
        "displayName-count-other": "European composite units",
        symbol: "XBA"
      },
      XBB: {
        displayName: "European Monetary Unit",
        "displayName-count-one": "European monetary unit",
        "displayName-count-other": "European monetary units",
        symbol: "XBB"
      },
      XBC: {
        displayName: "European Unit of Account (XBC)",
        "displayName-count-one": "European unit of account (XBC)",
        "displayName-count-other": "European units of account (XBC)",
        symbol: "XBC"
      },
      XBD: {
        displayName: "European Unit of Account (XBD)",
        "displayName-count-one": "European unit of account (XBD)",
        "displayName-count-other": "European units of account (XBD)",
        symbol: "XBD"
      },
      XCD: {
        displayName: "East Caribbean Dollar",
        "displayName-count-one": "East Caribbean dollar",
        "displayName-count-other": "East Caribbean dollars",
        symbol: "EC$",
        "symbol-alt-narrow": "$"
      },
      XDR: {
        displayName: "Special Drawing Rights",
        "displayName-count-one": "special drawing rights",
        "displayName-count-other": "special drawing rights",
        symbol: "XDR"
      },
      XEU: {
        displayName: "European Currency Unit",
        "displayName-count-one": "European currency unit",
        "displayName-count-other": "European currency units",
        symbol: "XEU"
      },
      XFO: {
        displayName: "French Gold Franc",
        "displayName-count-one": "French gold franc",
        "displayName-count-other": "French gold francs",
        symbol: "XFO"
      },
      XFU: {
        displayName: "French UIC-Franc",
        "displayName-count-one": "French UIC-franc",
        "displayName-count-other": "French UIC-francs",
        symbol: "XFU"
      },
      XOF: {
        displayName: "West African CFA Franc",
        "displayName-count-one": "West African CFA franc",
        "displayName-count-other": "West African CFA francs",
        symbol: "F CFA"
      },
      XPD: {
        displayName: "Palladium",
        "displayName-count-one": "troy ounce of palladium",
        "displayName-count-other": "troy ounces of palladium",
        symbol: "XPD"
      },
      XPF: {
        displayName: "CFP Franc",
        "displayName-count-one": "CFP franc",
        "displayName-count-other": "CFP francs",
        symbol: "CFPF"
      },
      XPT: {
        displayName: "Platinum",
        "displayName-count-one": "troy ounce of platinum",
        "displayName-count-other": "troy ounces of platinum",
        symbol: "XPT"
      },
      XRE: {
        displayName: "RINET Funds",
        "displayName-count-one": "RINET Funds unit",
        "displayName-count-other": "RINET Funds units",
        symbol: "XRE"
      },
      XSU: {
        displayName: "Sucre",
        "displayName-count-one": "Sucre",
        "displayName-count-other": "Sucres",
        symbol: "XSU"
      },
      XTS: {
        displayName: "Testing Currency Code",
        "displayName-count-one": "Testing Currency unit",
        "displayName-count-other": "Testing Currency units",
        symbol: "XTS"
      },
      XUA: {
        displayName: "ADB Unit of Account",
        "displayName-count-one": "ADB unit of account",
        "displayName-count-other": "ADB units of account",
        symbol: "XUA"
      },
      XXX: {
        displayName: "Unknown Currency",
        "displayName-count-one": "(unknown unit of currency)",
        "displayName-count-other": "(unknown currency)",
        symbol: "¤"
      },
      YDD: {
        displayName: "Yemeni Dinar",
        "displayName-count-one": "Yemeni dinar",
        "displayName-count-other": "Yemeni dinars",
        symbol: "YDD"
      },
      YER: {
        displayName: "Yemeni Rial",
        "displayName-count-one": "Yemeni rial",
        "displayName-count-other": "Yemeni rials",
        symbol: "YER"
      },
      YUD: {
        displayName: "Yugoslavian Hard Dinar (1966–1990)",
        "displayName-count-one": "Yugoslavian hard dinar (1966–1990)",
        "displayName-count-other": "Yugoslavian hard dinars (1966–1990)",
        symbol: "YUD"
      },
      YUM: {
        displayName: "Yugoslavian New Dinar (1994–2002)",
        "displayName-count-one": "Yugoslavian new dinar (1994–2002)",
        "displayName-count-other": "Yugoslavian new dinars (1994–2002)",
        symbol: "YUM"
      },
      YUN: {
        displayName: "Yugoslavian Convertible Dinar (1990–1992)",
        "displayName-count-one": "Yugoslavian convertible dinar (1990–1992)",
        "displayName-count-other": "Yugoslavian convertible dinars (1990–1992)",
        symbol: "YUN"
      },
      YUR: {
        displayName: "Yugoslavian Reformed Dinar (1992–1993)",
        "displayName-count-one": "Yugoslavian reformed dinar (1992–1993)",
        "displayName-count-other": "Yugoslavian reformed dinars (1992–1993)",
        symbol: "YUR"
      },
      ZAL: {
        displayName: "South African Rand (financial)",
        "displayName-count-one": "South African rand (financial)",
        "displayName-count-other": "South African rands (financial)",
        symbol: "ZAL"
      },
      ZAR: {
        displayName: "South African Rand",
        "displayName-count-one": "South African rand",
        "displayName-count-other": "South African rand",
        symbol: "ZAR",
        "symbol-alt-narrow": "R"
      },
      ZMK: {
        displayName: "Zambian Kwacha (1968–2012)",
        "displayName-count-one": "Zambian kwacha (1968–2012)",
        "displayName-count-other": "Zambian kwachas (1968–2012)",
        symbol: "ZMK"
      },
      ZMW: {
        displayName: "Zambian Kwacha",
        "displayName-count-one": "Zambian kwacha",
        "displayName-count-other": "Zambian kwachas",
        symbol: "ZMW",
        "symbol-alt-narrow": "ZK"
      },
      ZRN: {
        displayName: "Zairean New Zaire (1993–1998)",
        "displayName-count-one": "Zairean new zaire (1993–1998)",
        "displayName-count-other": "Zairean new zaires (1993–1998)",
        symbol: "ZRN"
      },
      ZRZ: {
        displayName: "Zairean Zaire (1971–1993)",
        "displayName-count-one": "Zairean zaire (1971–1993)",
        "displayName-count-other": "Zairean zaires (1971–1993)",
        symbol: "ZRZ"
      },
      ZWD: {
        displayName: "Zimbabwean Dollar (1980–2008)",
        "displayName-count-one": "Zimbabwean dollar (1980–2008)",
        "displayName-count-other": "Zimbabwean dollars (1980–2008)",
        symbol: "ZWD"
      },
      ZWL: {
        displayName: "Zimbabwean Dollar (2009)",
        "displayName-count-one": "Zimbabwean dollar (2009)",
        "displayName-count-other": "Zimbabwean dollars (2009)",
        symbol: "ZWL"
      },
      ZWR: {
        displayName: "Zimbabwean Dollar (2008)",
        "displayName-count-one": "Zimbabwean dollar (2008)",
        "displayName-count-other": "Zimbabwean dollars (2008)",
        symbol: "ZWR"
      }
    },
    localeCurrency: "GBP"
  },
  calendar: {
    patterns: {
      d: "dd/MM/y",
      D: "EEEE, d MMMM y",
      m: "d MMM",
      M: "d MMMM",
      y: "MMM y",
      Y: "MMMM y",
      F: "EEEE, d MMMM y HH:mm:ss",
      g: "dd/MM/y HH:mm",
      G: "dd/MM/y HH:mm:ss",
      t: "HH:mm",
      T: "HH:mm:ss",
      s: "yyyy'-'MM'-'dd'T'HH':'mm':'ss",
      u: "yyyy'-'MM'-'dd HH':'mm':'ss'Z'"
    },
    dateTimeFormats: {
      full: "{1} 'at' {0}",
      long: "{1} 'at' {0}",
      medium: "{1}, {0}",
      short: "{1}, {0}",
      availableFormats: {
        Bh: "h B",
        Bhm: "h:mm B",
        Bhms: "h:mm:ss B",
        d: "d",
        E: "ccc",
        EBhm: "E h:mm B",
        EBhms: "E h:mm:ss B",
        Ed: "E d",
        Ehm: "E h:mm a",
        EHm: "E HH:mm",
        Ehms: "E h:mm:ss a",
        EHms: "E HH:mm:ss",
        Gy: "y G",
        GyMd: "d/M/y GGGGG",
        GyMMM: "MMM y G",
        GyMMMd: "d MMM y G",
        GyMMMEd: "E, d MMM y G",
        h: "h a",
        H: "HH",
        hm: "h:mm a",
        Hm: "HH:mm",
        hms: "h:mm:ss a",
        Hms: "HH:mm:ss",
        hmsv: "h:mm:ss a v",
        Hmsv: "HH:mm:ss v",
        hmv: "h:mm a v",
        Hmv: "HH:mm v",
        M: "L",
        Md: "dd/MM",
        MEd: "E, dd/MM",
        MMdd: "dd/MM",
        MMM: "LLL",
        MMMd: "d MMM",
        MMMEd: "E, d MMM",
        MMMMd: "d MMMM",
        "MMMMW-count-one": "'week' W 'of' MMMM",
        "MMMMW-count-other": "'week' W 'of' MMMM",
        ms: "mm:ss",
        y: "y",
        yM: "MM/y",
        yMd: "dd/MM/y",
        yMEd: "E, dd/MM/y",
        yMMM: "MMM y",
        yMMMd: "d MMM y",
        yMMMEd: "E, d MMM y",
        yMMMM: "MMMM y",
        yQQQ: "QQQ y",
        yQQQQ: "QQQQ y",
        "yw-count-one": "'week' w 'of' Y",
        "yw-count-other": "'week' w 'of' Y"
      }
    },
    timeFormats: {
      full: "HH:mm:ss zzzz",
      long: "HH:mm:ss z",
      medium: "HH:mm:ss",
      short: "HH:mm"
    },
    dateFormats: {
      full: "EEEE, d MMMM y",
      long: "d MMMM y",
      medium: "d MMM y",
      short: "dd/MM/y"
    },
    days: {
      format: {
        abbreviated: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
        narrow: ["S", "M", "T", "W", "T", "F", "S"],
        short: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
        wide: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      },
      "stand-alone": {
        abbreviated: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
        narrow: ["S", "M", "T", "W", "T", "F", "S"],
        short: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
        wide: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      }
    },
    months: {
      format: {
        abbreviated: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"],
        narrow: ["J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"],
        wide: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
      },
      "stand-alone": {
        abbreviated: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"],
        narrow: ["J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"],
        wide: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
      }
    },
    quarters: {
      format: {
        abbreviated: ["Q1", "Q2", "Q3", "Q4"],
        narrow: ["1", "2", "3", "4"],
        wide: ["1st quarter", "2nd quarter", "3rd quarter", "4th quarter"]
      },
      "stand-alone": {
        abbreviated: ["Q1", "Q2", "Q3", "Q4"],
        narrow: ["1", "2", "3", "4"],
        wide: ["1st quarter", "2nd quarter", "3rd quarter", "4th quarter"]
      }
    },
    dayPeriods: {
      format: {
        abbreviated: {
          midnight: "midnight",
          am: "am",
          "am-alt-variant": "am",
          noon: "noon",
          pm: "pm",
          "pm-alt-variant": "pm",
          morning1: "in the morning",
          afternoon1: "in the afternoon",
          evening1: "in the evening",
          night1: "at night"
        },
        narrow: {
          midnight: "mi",
          am: "a",
          "am-alt-variant": "am",
          noon: "n",
          pm: "p",
          "pm-alt-variant": "pm",
          morning1: "in the morning",
          afternoon1: "in the afternoon",
          evening1: "in the evening",
          night1: "at night"
        },
        wide: {
          midnight: "midnight",
          am: "am",
          "am-alt-variant": "am",
          noon: "noon",
          pm: "pm",
          "pm-alt-variant": "pm",
          morning1: "in the morning",
          afternoon1: "in the afternoon",
          evening1: "in the evening",
          night1: "at night"
        }
      },
      "stand-alone": {
        abbreviated: {
          midnight: "midnight",
          am: "am",
          "am-alt-variant": "am",
          noon: "noon",
          pm: "pm",
          "pm-alt-variant": "pm",
          morning1: "morning",
          afternoon1: "afternoon",
          evening1: "evening",
          night1: "night"
        },
        narrow: {
          midnight: "midnight",
          am: "am",
          "am-alt-variant": "am",
          noon: "noon",
          pm: "pm",
          "pm-alt-variant": "pm",
          morning1: "morning",
          afternoon1: "afternoon",
          evening1: "evening",
          night1: "night"
        },
        wide: {
          midnight: "midnight",
          am: "am",
          "am-alt-variant": "am",
          noon: "noon",
          pm: "pm",
          "pm-alt-variant": "pm",
          morning1: "morning",
          afternoon1: "afternoon",
          evening1: "evening",
          night1: "night"
        }
      }
    },
    eras: {
      format: {
        wide: {
          "0": "Before Christ",
          "1": "Anno Domini",
          "0-alt-variant": "Before Common Era",
          "1-alt-variant": "Common Era"
        },
        abbreviated: {
          "0": "BC",
          "1": "AD",
          "0-alt-variant": "BCE",
          "1-alt-variant": "CE"
        },
        narrow: {
          "0": "B",
          "1": "A",
          "0-alt-variant": "BCE",
          "1-alt-variant": "CE"
        }
      }
    },
    gmtFormat: "GMT{0}",
    gmtZeroFormat: "GMT",
    dateFields: {
      era: {
        wide: "era",
        short: "era",
        narrow: "era"
      },
      year: {
        wide: "year",
        short: "yr",
        narrow: "yr"
      },
      quarter: {
        wide: "quarter",
        short: "qtr",
        narrow: "qtr"
      },
      month: {
        wide: "month",
        short: "mo",
        narrow: "mo"
      },
      week: {
        wide: "week",
        short: "wk",
        narrow: "wk"
      },
      weekOfMonth: {
        wide: "week of month",
        short: "wk of mo",
        narrow: "wk of mo"
      },
      day: {
        wide: "day",
        short: "day",
        narrow: "day"
      },
      dayOfYear: {
        wide: "day of year",
        short: "day of yr",
        narrow: "day of yr"
      },
      weekday: {
        wide: "day of the week",
        short: "day of wk",
        narrow: "day of wk"
      },
      weekdayOfMonth: {
        wide: "weekday of the month",
        short: "wkday of mo",
        narrow: "wkday of mo"
      },
      dayperiod: {
        short: "am/pm",
        wide: "am/pm",
        narrow: "am/pm"
      },
      hour: {
        wide: "hour",
        short: "hr",
        narrow: "hr"
      },
      minute: {
        wide: "minute",
        short: "min",
        narrow: "min"
      },
      second: {
        wide: "second",
        short: "sec",
        narrow: "sec"
      },
      zone: {
        wide: "time zone",
        short: "zone",
        narrow: "zone"
      },
      millisecond: {
        narrow: "ms",
        short: "ms",
        wide: "millisecond"
      }
    }
  },
  firstDay: 1,
  weekendRange: {
    start: 6,
    end: 0
  },
  likelySubtags: {
    en: "en-Latn-US"
  }
});