import {
    trigger,
    transition,
    style,
    query,
    group,
    animateChild,
    animate,
    keyframes,
    state
} from '@angular/animations'


export const sideMenu =
    trigger('sideMenu', [
        state('start', style({ width: 260 + 'px', 'position': 'fixed' })),
        state('end', style({ width: 85 + 'px', 'position': 'fixed' })),
        transition('start <=> end', animate('500ms'))
    ]);
export const deployCont =
    trigger('deployCont', [
        state('start', style({ width: 0 + 'px', visibility: "hidden" })),
        state('end', style({ width: 600 + 'px', margin: 20 + 'px', visibility: "visible" })),
        transition('start <=> end', animate(450))
    ]);
export const versionCont =
    trigger('versionCont', [
        state('start', style({ width: 0 + 'px', visibility: "hidden" })),
        state('end', style({ width: 600 + 'px', margin: 20 + 'px', visibility: "visible" })),
        transition('start <=> end', animate(450))
    ])
export const content =
    trigger('content', [
        /*  state('login', style({ 'padding-left': 0 + 'px' })), */
        state('start', style({ 'padding-left': 260 + 'px' })),
        state('end', style({ 'padding-left': 85 + 'px' })),
        transition('start <=> end', animate('500ms'))
    ])
export const fader =
    trigger('routeAnimations', [
        transition('*<=>*', [
            query(':enter, :leave', [
                style({
                    position: 'absolute',
                    left: 0,
                    width: '100%',
                    opacity: 0,
                    transform: 'scale(0) translateY(100%) '

                })
            ], { optional: true }),
            query(':enter', [
                animate('600ms ease',
                    style({ opacity: 1, transform: 'scale(1) translateY(0)' }))
            ], { optional: true })
        ])

    ])


//     GET = /api/en/3000/core/account/info?value=RLS_FACIK&search_by=login&host_id=3000&plain=false


// POST = /api/en/3000/core/account/info

// {
//   "value": "RLS_FACIK",
//   "search_by": "login",
//   "host_id": 3000,
//   "plain": false // optional return result in response
// }