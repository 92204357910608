//@ts-nocheck
import { Formio } from '@formio/angular';
const Components = (Formio as any).Components;
const Base = (Formio as any).Components.components.component;
// components\checkbox\Checkbox.js
const Templates = (Formio as any).Templates;

Templates.current = {
  slider: {
    form: (ctx:any) => `
<label class="ikbs-switch">
    <input
      ref="input"
      name="data[defaultValue]"
      type="checkbox"
      class="form-check-input ikbs-sw-input"
      lang="en"
      placeholder="Default Value"
      value="0"
      aria-required="false"
      >
       <span class="ikbs-slider round"></span>
       <span class="ikbs-slider-text"> ${ctx.component.label}</span>
    </input>
  </label>
`,
  },};
  export default class SliderComponent extends Base {
  static schema() {
    return Base.schema({
      type: 'SliderComponent',
      inputType: 'checkbox',
      label: 'Slider',
      key: 'checkbox',
      dataGridLabel: true,
      labelPosition: 'right',
      value: '',
      name: ''
    });
  }

  static get builderInfo() {
    return {
      title: 'Slider',
      group: 'basic',
      icon: 'check-square',
      documentation: '/userguide/form-building/form-components#check-box',
      weight: 50,
      schema: SliderComponent.schema()
    };
  }
    get defaultSchema() {
      return SliderComponent.schema();
    }

  render() {
    // console.log(this['renderTemplate']('slider'))
    // console.log(this['renderTemplate']('checkbox', {
    //   input: this.inputInfo,
    //   checked: this['checked'],
    //   tooltip: this['interpolate'](this['t'](this['component'].tooltip) || '', { _userInput: true }).replace(/(?:\r\n|\r|\n)/g, '<br />')
    // }))
    return super.render(`
<label class="ikbs-switch">
    <input
      ref="input"
      name="data[defaultValue]"
      type="checkbox"
      class="form-check-input ikbs-sw-input"
      lang="en"
      placeholder="Default Value"
      value="0"
      aria-required="false"
      ${this.component.disabled?'disabled="disabled"':'""'}
      >
       <span class="ikbs-slider round"></span>
    </input>
  </label>
       <label class="ikbs-slider-text"> ${this.component.label}</label>
`);
  }

    get labelClass() {
      let className = '';
      if (this.isInputComponent
        && !this.options.inputsOnly
        && this.component.validate
        && this.component.validate.required) {
        className += ' field-required';
      }
      return `${className}`;
    }

    get inputInfo() {
      const info = super.elementInfo();
      info.type = 'input';
      info.changeEvent = 'click';
      info.attr.type = this.component.inputType || 'checkbox';
      info.attr.class = 'form-check-input';
      if (this.component.name) {
        info.attr.name = `data[${this.component.name}]`;
      }
      info.attr.value = this.component.value ? this.component.value : 0;
      info.label = this.t(this.component.label, { _userInput: true });
      info.labelClass = this.labelClass ;
      return info;
    }

  attach(element:any) {
    this['loadRefs'](element, { input: 'multiple' });
    this['input'] = this['refs'].input[0];
    if (this['refs'].input) {
      this['addEventListener'](this['input'], this.inputInfo.changeEvent, () => this['updateValue'](null, {
        modified: true
      }));
      this['addShortcut'](this['input']);
    }
    return super.attach(element);
  }


    detach(element) {
      if (element && this.input) {
        this.removeShortcut(this.input);
      }
      super.detach();
    }

    get emptyValue() {
      return this.component.inputType === 'radio' ? null : false;
    }

    isEmpty(value = this.dataValue) {
      return super.isEmpty(value) || value === false;
    }

    get key() {
      return this.component.name ? this.component.name : super.key;
    }

    getValueAt(index) {
      if (this.component.name) {
        return this.refs.input[index].checked ? this.component.value : '';
      }
      return !!this.refs.input[index].checked;
    }

    getValue() {
      const value = super.getValue();
      if (this.component.name) {
        return value ? this.setCheckedState(value) : this.setCheckedState(this.dataValue);
      }
      else {
        return (value === '') ? this.dataValue : !!value;
      }
    }

    get checked() {
      if (this.component.name) {
        return (this.dataValue === this.component.value);
      }
      return !!this.dataValue;
    }

    setCheckedState(value) {
      if (!this.input) {
        return;
      }
      if (this.component.name) {
        this.input.value = (value === this.component.value) ? this.component.value : 0;
        this.input.checked = (value === this.component.value) ? 1 : 0;
      }
      else if (value === 'on') {
        this.input.value = 1;
        this.input.checked = 1;
      }
      else if (value === 'off') {
        this.input.value = 0;
        this.input.checked = 0;
      }
      else if (value) {
        this.input.value = 1;
        this.input.checked = 1;
      }
      else {
        this.input.value = 0;
        this.input.checked = 0;
      }
      if (this.input.checked) {
        this.input.setAttribute('checked', true);
      }
      else {
        this.input.removeAttribute('checked');
      }
      return value;
    }

    setValue(value, flags = {}) {
      if (
        this.setCheckedState(value) !== undefined ||
        (!this.input && value !== undefined && (this.visible || this.conditionallyVisible() || !this.component.clearOnHide))
      ) {
        const changed = this.updateValue(value, flags);
        if (this.isHtmlRenderMode() && flags && flags.fromSubmission && changed) {
          this.redraw();
        }
        return changed;
      }
      return false;
    }

    getValueAsString(value) {
      const { name: componentName, value: componentValue } = this.component;
      const hasValue = componentName ? _.isEqual(value, componentValue) : value;
      if (_.isUndefined(value) && this.inDataTable) {
        return '';
      }

      return this.t(hasValue ? 'Yes' : 'No');
    }

    updateValue(value, flags) {
      // If this is a radio and is alredy checked, uncheck it.
      if (this.component.name && flags.modified && (this.dataValue === this.component.value)) {
        this.input.checked = 0;
        this.input.value = 0;
        this.dataValue = '';
        this.updateOnChange(flags, true);
      }

      const changed = super.updateValue(value, flags);

      // Update attributes of the input element
      if (changed && this.input) {
        if (this.input.checked) {
          this.input.setAttribute('checked', 'true');
        }
        else {
          this.input.removeAttribute('checked');
        }
      }

      return changed;
    }
}

Components.addComponent('SliderComponent', SliderComponent);
