import { Inject } from "@angular/core";
import { FormioAppConfig } from "@formio/angular";
import { environment } from "../../environments/environment";

export const formioConfig: FormioAppConfig = {
  appUrl: "",
  apiUrl: environment.host,
};

// export class formioConfig {
//   constructor(@Inject('environment') private environment) {
//     console.log(environment.apiEndpoint);
//   }

// public baseUrl = () => {
//   console.log(this.environment.env);

//   return this.environment.apiEndpoint;
// };

// public appUrl = () => {
//   console.log(this.environment.env);

//   if (this.environment.env == 'prod') {
//     return 'https://crm.gbl-systems.com';
//   }
//   if (this.environment.env == 'prod') {
//     return 'https://crm.gbl-systems.com';
//   }
//   return 'https://crm-dev.gbl-systems.com';
// };

// public apiUrl = () => {
//   console.log(this.environment.env);
//   if (this.environment.env == 'prod') {
//     return 'https://crm.gbl-systems.com';
//   }
//   if (this.environment.env == 'prod') {
//     return 'https://crm.gbl-systems.com';
//   }
//   return 'https://crm-dev.gbl-systems.com';
// };
// }

//${that.baseUrl}/content/media/upload/${project}/${_type}/en
