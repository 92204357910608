// @ts-nocheck
import { Formio } from '@formio/angular';

const button = (Formio as any).Components.components.button;
const test = (Formio as any).Components.components;
const Input = (Formio as any).Components.components.Input;
const Components = (Formio as any).Components;
const Utils = (Formio as any).Utils;

export default class FormioButton extends button {
  // static schema(...extend) {
  //   return button.schema({
  //     type: 'button',
  //     label: 'Submit',
  //     key: 'submit',
  //     size: 'md',
  //     leftIcon: '',
  //     rightIcon: '',
  //     block: false,
  //     action: 'submit',
  //     persistent: false,
  //     disableOnInvalid: false,
  //     theme: 'primary',
  //     dataGridLabel: true
  //   }, ...extend);
  // }

  // static get builderInfo() {
  //   return {
  //     title: 'IKBS-Button',
  //     group: 'basic',
  //     icon: 'stop',
  //     documentation: '/userguide/#button',
  //     weight: 110,
  //     schema: FormioButton.schema()
  //   };
  // }

  // get defaultSchema() {
  //     return FormioButton.schema();
  // }

  // get inputInfo() {
  //     const info = super.elementInfo();
  //     info.type = 'input';
  //     info.attr.type = 'text';
  //     return info;
  // }

  // render() {
  //   console.log('debil')
  //   if (this.viewOnly || this.options.hideButtons) {
  //     this._visible = false;
  //   }
  //     return super.render(super.renderTemplate('button', {
  //       component: this.component,
  //       input: this.inputInfo
  //     }));
  // }

  // attach(element) {
  //     this.loadRefs(element, {
  //         button: 'single',
  //         buttonMessageContainer: 'single',
  //         buttonMessage: 'single'
  //     });

  //     const superAttach = super.attach(element);
  //     this.attachButton();
  //     return superAttach;
  // }

  onClick(event) {
    this.triggerReCaptcha();
    // Don't click if disabled or in builder mode.
    if (this.disabled || this.options.attachMode === 'builder') {
      return;
    }
    this.dataValue = true;
    if (this.component.action !== 'submit' && this.component.showValidations) {
      this.emit('checkValidity', this.data);
    }
    switch (this.component.action) {
      case 'saveState':
      case 'submit':
        event.preventDefault();
        event.stopPropagation();
        this.loading = true;
        var target = event.target;
        this.emit('submitButton', {
          state: this.component.state || 'submitted',
          component: this.component,
          instance: this,
        });
        const text = target.innerText;
        target.className = target.className.includes('btn-block')
          ? 'btn-block formioSubmitLoad'
          : 'formioSubmitLoad';
        target.innerHTML =
          text + '<i class=" ik-formio_loader refreshRotate"></i>';
        // target.appendChild(this.ce('i', { class: this.iconClass('x ik-formio_loader refreshRotate') }));
        // target.appendChild(this.text(text));
        break;
      case 'event':
        this.emit(this.interpolate(this.component.event), this.data);
        this.events.emit(this.interpolate(this.component.event), this.data);

        this.emit('customEvent', {
          type: this.interpolate(this.component.event),
          component: this.component,
          data: this.data,
          event: event,
        });
        break;
      case 'custom': {
        // Get the FormioForm at the root of this component's tree
        const form = this.getRoot();
        // Get the form's flattened schema components
        const flattened = Utils.flattenComponents(
          form.component.components,
          true
        );
        // Create object containing the corresponding HTML element components
        const components = {};
        Utils._.each(flattened, (component, key) => {
          const element = form.getComponent(key);
          if (element) {
            components[key] = element;
          }
        });

        this.evaluate(this.component.custom, {
          form,
          flattened,
          components,
        });
        break;
      }
      case 'url':
        this.loading = true;
        this.emit('requestButton', {
          component: this.component,
          instance: this,
        });
        this.emit('requestUrl', {
          url: this.interpolate(this.component.url),
          headers: this.component.headers,
        });
        break;
      case 'reset':
        this.emit('resetForm');
        break;
      case 'delete':
        this.emit('deleteSubmission');
        break;
      case 'oauth':
        if (this.root === this) {
          console.warn(
            'You must add the OAuth button to a form for it to function properly'
          );
          return;
        }

        // Display Alert if OAuth config is missing
        if (!this.oauthConfig) {
          this.root.setAlert(
            'danger',
            'OAuth not configured. You must configure oauth for your project before it will work.'
          );
          break;
        }

        // Display Alert if oAuth has an error is missing
        if (this.oauthConfig.error) {
          this.root.setAlert(
            'danger',
            `The Following Error Has Occured ${this.oauthConfig.error}`
          );
          break;
        }

        this.openOauth(this.oauthConfig);

        break;
    }
  }

  attachButton() {
    let that = this
    this.addShortcut(this.refs.button);
    let onChange = null;
    let onError = null;
    if (this.component.action === 'submit') {
      this.on(
        'submitButton',
        () => {
          this.disabled = true;
        },
        true
      );
      this.on(
        'submitDone',
        (message) => {
          const resultMessage = Utils._.isString(message)
            ? message
            : this.t('complete');
          this.loading = false;
          this.disabled = false;
          this.addClass(this.refs.button, 'btn-success '); //submit-success
          this.removeClass(this.refs.button, 'btn-danger submit-fail');
          this.addClass(this.refs.buttonMessageContainer, 'has-success');
          this.removeClass(this.refs.buttonMessageContainer, 'has-error');
          this.setContent(this.refs.buttonMessage, '');
          this.refs.button?.classList.remove('formioSubmitLoad');
          this.addClass(this.refs.button, 'btn btn-primary btn-md');
        },
        true
      );
      this.on(
        'submitError',
        (message) => {
          const resultMessage = Utils._.isString(message)
            ? message
            : this.t(this.errorMessage('submitError'));
          this.loading = false;
          this.disabled = false;
          this.hasError = true;
          this.removeClass(this.refs.button, 'btn-success submit-success');
          this.addClass(this.refs.button, 'btn-danger submit-fail');
          this.removeClass(this.refs.buttonMessageContainer, 'has-success');
          this.addClass(this.refs.buttonMessageContainer, 'has-error');
          this.setContent(this.refs.buttonMessage, resultMessage);
        },
        true
      );
      onChange = (value, isValid) => {
        this.removeClass(this.refs.button, 'btn-success submit-success');
        if (isValid) {
          this.removeClass(this.refs.button, 'btn-danger submit-fail');
          this.refs.button?.classList.remove('formioSubmitLoad');
          this.addClass(this.refs.button, 'btn btn-primary btn-md');

          if (this.hasError) {
            this.hasError = false;
            this.setContent(this.refs.buttonMessage, '');
            this.removeClass(this.refs.buttonMessageContainer, 'has-success');
            this.removeClass(this.refs.buttonMessageContainer, 'has-error');
          }
        }
      };
      onError = () => {
        this.hasError = true;
        this.removeClass(this.refs.button, 'btn-success submit-success');
        this.addClass(this.refs.button, 'btn-danger submit-fail');
        this.removeClass(this.refs.buttonMessageContainer, 'has-success');
        this.addClass(this.refs.buttonMessageContainer, 'has-error');
        this.setContent(
          this.refs.buttonMessage,
          this.t(this.errorMessage('submitError'))
        );
      };
    }

    if (this.component.action === 'url') {
      this.on(
        'requestButton',
        () => {
          this.disabled = true;
        },
        true
      );
      this.on(
        'requestDone',
        () => {
          this.loading = false;
          this.disabled = false;
        },
        true
      );
    }

    this.on(
      'change',
      (value, flags) => {
        let isValid = value.isValid;
        //check root validity only if disableOnInvalid is set and when it is not possible to make submission because of validation errors
        if (
          flags &&
          flags.noValidate &&
          (this.component.disableOnInvalid || this.hasError)
        ) {
          isValid =
            flags.rootValidity ||
            (this.root
              ? this.root.checkValidity(this.root.data, null, null, true)
              : true);
          flags.rootValidity = isValid;
        }
        this.loading = false;
        this.disabled =
          this.shouldDisabled || (this.component.disableOnInvalid && !isValid);
        this.setDisabled(this.refs.button, this.disabled);

        if (onChange) {
          onChange(value, isValid);
        }
      },
      true
    );

    this.on(
      'error',
      () => {
        this.loading = false;
        this.disabled = false;
        if (onError) {
          onError();
        }
      },
      true
    );

    if (this.component.saveOnEnter) {
      this.root.addEventListener(this.root.element, 'keyup', function (event) {
        if (event.keyCode === 13) {
          that.onClick.call(that, event);
        }
      });
    }

    this.addEventListener(this.refs.button, 'click', this.onClick.bind(this));

    this.disabled = this.shouldDisabled;

    function getUrlParameter(name) {
      name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
      const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
      const results = regex.exec(location.search);
      if (!results) {
        return results;
      }
      return decodeURIComponent(results[1].replace(/\+/g, ' '));
    }

    // If this is an OpenID Provider initiated login, perform the click event immediately
    if (
      this.component.action === 'oauth' &&
      this.oauthConfig &&
      !this.oauthConfig.error
    ) {
      const iss = getUrlParameter('iss');
      if (iss && this.oauthConfig.authURI.indexOf(iss) === 0) {
        this.openOauth(this.oauthConfig);
      }
    }
  }
}
Components.setComponent('button', FormioButton);

// // Use the table component edit form.
// UserNameComponent.editForm = componentEditForm;

// // Register the component to the Formio.Components registry.
// Components.addComponent('username', UserNameComponent);
