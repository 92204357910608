import { inject, Injectable, NgZone } from "@angular/core";
import { SearchService } from "@services/search.service";
import { SocketService } from "@services/socket.service";
import { Panel_switcher } from "@services/panel-switcher";
import { Observable, Subject, Subscription, interval } from "rxjs";
import { GlobalListenersService } from "./global-listeners.service";
import { SwUpdate } from '@angular/service-worker';
import { AuthService } from "./auth.service";


@Injectable({
  providedIn: "root",
})
export class AppComponentService {
  SearchService: SearchService = inject(SearchService);
  SocketService: SocketService = inject(SocketService);
  Panel_switcher: Panel_switcher = inject(Panel_switcher);
  GlobalListenersService: GlobalListenersService = inject(GlobalListenersService);
  SwUpdate: SwUpdate = inject(SwUpdate)
  AuthService: AuthService = inject(AuthService)

  private currentDate = new Date().getTime();
  private clickedDate = new Date().getTime();
  private switchToIKBS: number = 0;
  private switchToHot: number = 0;
  private _online: any;
  private handlerKeyboard$: any;
  private sendSocket: boolean = true;
  private SWtimeInteval: Subscription | undefined
  private timeInteval: Subscription | undefined


  constructor() {
    this.checkUpdate()
    this.swUpdateService()
    if (!this.timeInteval) {
      inject(NgZone).runOutsideAngular(() => {
        this.timeInteval = interval(60 * 1000)
          .subscribe(data => {
            if (this.AuthService.isAuthenticated()) {
              if (this.currentDate < this.clickedDate) {
                this.SocketService.update_user_last_login();
                this.currentDate = this.clickedDate;
              }
            }
          });
      })
    }
  }
  /*  Check if user online Send event by socket */

  KeyboardEventhandler() {
    this.clickedDate = new Date().getTime();
  }

  swUpdateService() {
    if (!this.SWtimeInteval && this.SwUpdate.isEnabled) {
      const timeInteval = interval(60 * 1000)
      this.SWtimeInteval = timeInteval.subscribe((el) => {
        this.SwUpdate.checkForUpdate()
      })
    }
  }

  checkUpdate() {
    this.SwUpdate.versionUpdates.subscribe((el: any) => {
      if (el.type == "VERSION_READY") {
        if (confirm("New version available. Load New Version?")) {
          document.location.reload()
        }
      }
    });
    this.SwUpdate.unrecoverable.subscribe((el: any) => {
      console.log(el)
    });
  }


  /* CMS ctrl+F override Browser event with own search */

  controlF(event: any) {
    const { code, ctrlKey } = event;
    if (code === "KeyF" && ctrlKey) {
      this.SearchService?.searchListener(event, true);
    } else if (code === "Escape") {
      this.SearchService?.searchListener(event, false);
    }
  }

  panelSwitcher() {
    if (localStorage.getItem("IKAT") === "HOT_SOFTWARE") this.Panel_switcher.setSwich(false);
    if (localStorage.getItem("IKAT") === "IKBS_TOOL") this.Panel_switcher.setSwich(true);
  }

  whiteBlackTheme(event: any) {
    if (event?.className && typeof event?.className == "string") {
      if (event.className.indexOf("hotter") !== -1) {
        ++this.switchToHot;
        if (this.switchToHot == 7) {
          this.Panel_switcher.setSwich(false);
          localStorage.setItem("IKAT", "HOT_SOFTWARE");
          window.location.reload();
        }
        return;
      }
      if (event?.className?.indexOf("menu_label") !== -1) {
        ++this.switchToIKBS;
        if (this.switchToIKBS == 7) {
          this.Panel_switcher.setSwich(true);
          localStorage.setItem("IKAT", "IKBS_TOOL");
          window.location.reload();
        }
        return;
      }
    }
    this.switchToIKBS = 0;
    this.switchToHot = 0;
  }

  // cashSwitcher(event){
  //   if (event.textContent == "CLEAR CASH: " || event.textContent == "FLUSH CASH: ") {
  //     this.flushCounter++
  //     if (this.flushCounter == 3) {
  //       this.isFLush = true
  //     }
  //     if (this.flushCounter == 6) {
  //       this.isFLush = false
  //       this.flushCounter = 0
  //     }
  //   }
  // }
}
